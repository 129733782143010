const config =
  process.env.REACT_APP_ENV !== 'prod'
    ? {
        s3: {
          REGION: 'eu-west-2',
          BUCKET: 'superhero-science-api-dev-attachments-bucket',
        },
        apiGateway: {
          REGION: 'eu-west-2',
          STUDENTS_URL:
            process.env.REACT_APP_ENV !== 'dev'
              ? 'http://localhost:8080/api'
              : 'https://6dlzsavmy5.execute-api.eu-west-2.amazonaws.com/dev',
          PARENTS_URL:
            process.env.REACT_APP_ENV !== 'dev'
              ? 'http://localhost:8080/api'
              : 'https://6dlzsavmy5.execute-api.eu-west-2.amazonaws.com/dev',
          TEAMS_URL:
            process.env.REACT_APP_ENV !== 'dev'
              ? 'http://localhost:8080/api'
              : 'https://6dlzsavmy5.execute-api.eu-west-2.amazonaws.com/dev',
          PUBLIC_URL:
            process.env.REACT_APP_ENV !== 'dev'
              ? 'http://localhost:8080/api'
              : 'https://6dlzsavmy5.execute-api.eu-west-2.amazonaws.com/dev',
        },
        cognito: {
          REGION: 'eu-west-2',
          USER_POOL_ID: 'eu-west-2_GRwh9zESz',
          APP_CLIENT_ID: '66v1q18dph3pfpboov081quujh',
          IDENTITY_POOL_ID: 'eu-west-2:4cec0119-bbae-482a-832e-351f13dec6ff',
        },
      }
    : {
        s3: {
          REGION: process.env.REACT_APP_REGION,
          BUCKET: process.env.REACT_APP_BUCKET,
        },
        apiGateway: {
          REGION: process.env.REACT_APP_REGION,
          STUDENTS_URL: process.env.REACT_APP_API_URL,
          PARENTS_URL: process.env.REACT_APP_API_URL,
          TEAMS_URL: process.env.REACT_APP_API_URL,
          PUBLIC_URL: process.env.REACT_APP_API_URL,
        },
        cognito: {
          REGION: process.env.REACT_APP_REGION,
          USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
          APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
          IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
        },
      };
export default {
  ...config,
};
