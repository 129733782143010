/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Box, Paper, Typography, Button, Link } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HelpIcon from '@material-ui/icons/Help';

export default function FAQ() {
  return (
    <Box marginX="20px">
      <Box paddingY="20px" />
      <Typography id="top" align="center" variant="h4" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Paper elevation={6}>
        <Box paddingY="10px" paddingX="20px">
          <Typography align="center" variant="h6" color="textSecondary">
            In this page you can find help for parents & teachers to hopefully answer some of the
            questions, queries and concerns you might have. In the event of any unforeseen
            difficulty during the course of the project, please feel free to contact us{' '}
            <Link color="inherit" underline="always" href="mailto:superheroscienceinfo@gmail.com">
              here
            </Link>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button variant="contained" color="secondary" component={NavLink} to="/ideas">
            Take me to examples of projects
          </Button>
        </Box>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              primary="Who can participate?"
              secondary={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography component="span" variant="body1" color="textPrimary">
                  All students attending school in the Maltese islands whom in the scholastic year
                  2023/2024 are in year 3 to year 8 (Form 2).
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              primary="Is it free?"
              secondary={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography component="span" variant="body1" color="textPrimary">
                  Yes, all participation is free and voluntary
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              primary="When is is the deadline to apply?"
              secondary={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography component="span" variant="body1" color="textPrimary">
                  The deadline is on the June 2 2024 at 23:59.
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              primary="Can I be in a team?"
              secondary={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography component="span" variant="body1" color="textPrimary">
                  Of course! You can be a maximum of 3 students in one group, but you can also
                  participate individually.
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              primary="What do I win?"
              secondary={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography component="span" variant="body1" color="textPrimary">
                  Visit the Prizes section of the website to check the amazing prizes!
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              primary="How can I get in touch if I have an issue or question?"
              secondary={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography component="span" variant="body1" color="textPrimary">
                  Email us at superheroscienceinfo@gmail.com or call on +356 99052482
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
}
