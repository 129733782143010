import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MuiAlert from '@material-ui/lab/Alert';
import uuid from 'react-uuid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function calculateCountdown(endDate) {
  let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
  // clear countdown when date is reached
  if (diff <= 0) return false;

  const timeLeft = {
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  };

  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = diff;

  return timeLeft;
}
function addLeadingZeros(value) {
  let newValue = String(value);
  while (newValue.length < 2) {
    newValue = `0${newValue}`;
  }
  return newValue;
}

export default function Countdown(props) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [timeOver, setTimeOver] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const interval = setInterval(() => {
      const { date } = props;
      const newDate = calculateCountdown(date);
      if (newDate) {
        setDays(newDate.days);
        setHours(newDate.hours);
        setMin(newDate.min);
        setSec(newDate.sec);
      } else {
        setTimeOver(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return timeOver ? (
    <MuiAlert elevation={6} variant="filled" severity="success">
      Time is up! Your project has been submitted to the judges - Well done and goodluck!
    </MuiAlert>
  ) : (
    <Box display="flex" flexDirection="row">
      {[
        { unit: days, single: 'day', multiple: 'days' },
        { unit: hours, single: 'hour', multiple: 'hours' },
        { unit: min, single: 'min', multiple: 'mins' },
        { unit: sec, single: 'sec', multiple: 'secs' },
      ].map((timeUnit) => (
        <Box display="flex" alignItems="flex-end" marginX="5px" key={uuid()}>
          <Typography display="inline" variant={matches ? 'h6' : 'h5'}>
            {addLeadingZeros(timeUnit.unit)}
          </Typography>
          <Typography display="inline" variant={matches ? 'body1' : 'h6'}>
            {timeUnit.unit === 1 ? timeUnit.single : timeUnit.multiple}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

Countdown.propTypes = {
  date: PropTypes.string.isRequired,
};
