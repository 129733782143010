import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Card, CardActions, CardContent, Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    margin: '10px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardActions: {
    justifyContent: 'center',
  },
  media: {
    height: 140,
  },
  superheroImage: {
    height: '150px',
  },
}));

export default function ArticleSummary(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { timeStamp, title, summary, image, imageSrc, cta, ctaText, ctaLink } = props;
  return (
    <Card
      variant="outlined"
      classes={{
        root: classes.card,
      }}
    >
      <CardContent className={classes.cardContent}>
        <Typography align="right" variant="body1" color="textPrimary">
          {timeStamp}
        </Typography>
        <Typography align="center" variant="h4" color="textPrimary" gutterBottom>
          {title}
        </Typography>

        <Box display="flex" justifyContent="center">
          {image && (
            <img src={imageSrc} alt="Thumbnail for post" height={matches ? '150px' : '300px'} />
          )}
        </Box>
        <Box paddingY="5px" />
        {typeof summary === 'string' ? (
          <Typography align="justify" variant="body1">
            {summary}
          </Typography>
        ) : (
          [
            <Box paddingY="10px" />,
            summary.map((text) => (
              <Typography align="center" variant="body1">
                {text}
              </Typography>
            )),
          ]
        )}
      </CardContent>
      {cta && (
        <CardActions className={classes.cardActions}>
          <Button variant="outlined" color="primary" size="medium" component={NavLink} to={ctaLink}>
            {ctaText}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

ArticleSummary.propTypes = {
  timeStamp: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.oneOfType(PropTypes.string, PropTypes.arrayOf(PropTypes.string)).isRequired,
  image: PropTypes.bool,
  imageSrc: PropTypes.string,
  cta: PropTypes.bool,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
};

ArticleSummary.defaultProps = {
  image: false,
  imageSrc: '',
  cta: false,
  ctaLink: '',
  ctaText: '',
};
