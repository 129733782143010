/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joyride, { STATUS, ACTIONS } from 'react-joyride';
import { useHistory, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupIcon from '@material-ui/icons/Group';
import HistoryIcon from '@material-ui/icons/History';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player';
import { DropzoneDialog } from 'material-ui-dropzone';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { fileToBase64, imageProcessor, resize, sharpen } from 'ts-image-processor';
import uuid from 'react-uuid';
import { useUser, useUserActions } from '../contexts/User';
import ApplicationSection from '../components/ApplicationSection';
import Countdown from '../components/Countdown';
import { useAlertActions } from '../contexts/Alert';
import { useAuth } from '../contexts/Authentication';
import VidStorageLib from '../libs/vid-storage-lib';
import CircularProgressWithLabel from '../components/CircularProgressWithLabel';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  gridListTile: {
    height: 'unset',
    width: 'unset',
  },
  gridList: {
    width: '80%',
    height: '450px',
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  gridListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <Paper
      className={classes.root}
      elevation={6}
      square
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Paper>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export default function Application() {
  const classes = useStyles();
  const { studentDetails, currentApplication, attachments, videos } = useUser();
  const { cognitoUserObject } = useAuth();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    getSubmission,
    updateSubmission,
    removeAttachment,
    uploadAttachment,
    getAttachments,
    getVideos,
    getTeamMembers,
    changeTeam,
    requestUploadVideoLink,
    removeVideo,
  } = useUserActions();
  const [title, setTitle] = useState(' ');
  const [aims, setAims] = useState(' ');
  const [abstract, setAbstract] = useState(' ');
  const [method, setMethod] = useState(' ');
  const [results, setResults] = useState(' ');
  const [discussion, setDiscussion] = useState(' ');
  const [newTeam, setNewTeam] = useState(' ');
  const [attachmentKeys, setAttachmentKeys] = useState([]);
  const [videoKeys, setVideoKeys] = useState([]);
  const [value, setValue] = React.useState(1);
  const [everythingDisabled, setEverythingDisabled] = React.useState(true);
  const [imagesLoaded, setImagesLoaded] = React.useState(false);
  const [videosLoaded, setVideosLoaded] = React.useState(false);
  const [openUploadImages, setOpenUploadImages] = useState(false);
  const [openUploadVideos, setOpenUploadVideos] = useState(false);
  const [tour, setTour] = useState(false);
  const [videoUploaded, setVideoUploaded] = useState(true);
  const [progress, setProgress] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const endDate = 'June 2, 2024 23:59:59';
  const { showAlert } = useAlertActions();

  const handleChange = (event, newValue) => {
    if (
      studentDetails &&
      currentApplication &&
      newValue === 7 &&
      currentApplication.attachments !== [] &&
      !imagesLoaded
    ) {
      setImagesLoaded(false);
      const params = {
        teamId: studentDetails.teamId,
        studentId: studentDetails.studentId,
        isParent: cognitoUserObject.attributes['custom:isParent'],
      };
      getAttachments(params, currentApplication.attachments || []);
    }
    if (
      studentDetails &&
      currentApplication &&
      newValue === 8 &&
      currentApplication.videos !== [] &&
      !videosLoaded
    ) {
      setVideosLoaded(false);
      const params = {
        teamId: studentDetails.teamId,
        studentId: studentDetails.studentId,
        isParent: cognitoUserObject.attributes['custom:isParent'],
      };
      getVideos(params, currentApplication.videos || []);
    }
    setValue(newValue);
  };

  const removeDocument = (key) => {
    const params = {
      teamId: studentDetails.teamId,
      studentId: studentDetails.studentId,
      isParent: cognitoUserObject.attributes['custom:isParent'],
    };
    removeAttachment({ ...params, key }).then(() => {
      setImagesLoaded(false);
      getSubmission(params).then((newApplication) => {
        getAttachments(params, newApplication.attachments || []);
        setAttachmentKeys(newApplication.attachments || []);
      });
    });
  };

  const handleRemoveVideo = (key) => {
    const params = {
      teamId: studentDetails.teamId,
      studentId: studentDetails.studentId,
    };
    removeVideo({ ...params, key }).then(() => {
      setVideosLoaded(false);
      getSubmission(params).then((newApplication) => {
        getVideos(params, newApplication.videos || []);
      });
    });
  };

  const saveChanges = (event) => {
    event.preventDefault();
    const params = {
      studentId: studentDetails.studentId,
      teamId: studentDetails.teamId,
      applicationtitle: title.length > 0 ? title : ' ',
      applicationaims: aims.length > 0 ? aims : ' ',
      applicationabstract: abstract.length > 0 ? abstract : ' ',
      applicationmethod: method.length > 0 ? method : ' ',
      applicationresults: results.length > 0 ? results : ' ',
      applicationdiscussion: discussion.length > 0 ? discussion : ' ',
    };
    updateSubmission(params).then(() => {
      const params2 = {
        teamId: studentDetails.teamId,
        studentId: studentDetails.studentId,
        isParent: cognitoUserObject.attributes['custom:isParent'],
      };
      getSubmission(params2);
    });
  };

  const onChangeTeam = () => {
    changeTeam(newTeam.trim(), studentDetails.studentId).then(() => {
      history.push('/dashboard');
    });
  };

  const onRevertTeam = async () => {
    changeTeam(studentDetails.oldTeamId, studentDetails.studentId).then(() => {
      history.push('/dashboard');
    });
  };

  useEffect(() => {
    if (studentDetails && !currentApplication) {
      setImagesLoaded(false);
      const params = {
        teamId: studentDetails.teamId,
        studentId: studentDetails.studentId,
        isParent: cognitoUserObject.attributes['custom:isParent'],
      };
      getSubmission(params);
      getTeamMembers(params);
    }
    if (currentApplication) {
      setEverythingDisabled(cognitoUserObject.attributes['custom:isParent'] === 'true');
      setTitle(currentApplication.applicationtitle || ' ');
      setAims(currentApplication.applicationaims || ' ');
      setAbstract(currentApplication.applicationabstract || ' ');
      setMethod(currentApplication.applicationmethod || ' ');
      setResults(currentApplication.applicationresults || ' ');
      setDiscussion(currentApplication.applicationdiscussion || ' ');
      setAttachmentKeys(currentApplication.attachments || []);
      setVideoKeys(currentApplication.videos || []);
    }
  }, [studentDetails, currentApplication, cognitoUserObject.attributes]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.parse(new Date(endDate)) - Date.parse(new Date()) <= 0) {
        setEverythingDisabled(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  function pFileReader(file) {
    return new Promise((resolve, reject) => {
      fileToBase64(file).then((base64) => {
        imageProcessor
          .src(base64)
          .pipe(resize({ maxWidth: 1024, maxHeight: 1024 }), sharpen({ sharpness: 1 }))
          .then((processedBase64) => {
            uploadAttachment({
              studentId: studentDetails.studentId,
              teamId: studentDetails.teamId,
              file: processedBase64,
            })
              .then(() => resolve())
              .catch((e) => reject(e));
          });
      });
    });
  }

  const uploadAttachments = async (uploads) => {
    setImagesLoaded(false);
    const promises = uploads.map(pFileReader);
    await Promise.all(promises)
      .then(() => {
        showAlert('Files uploaded successfully!', 'success');
        const params = {
          teamId: studentDetails.teamId,
          studentId: studentDetails.studentId,
          isParent: cognitoUserObject.attributes['custom:isParent'],
        };
        getSubmission(params).then((newApplication) => {
          getAttachments(params, newApplication.attachments || []);
          setAttachmentKeys(newApplication.attachments || []);
        });
      })
      .catch((e) => {
        showAlert('There was a problem uploading your files', 'error', e);
      });
  };

  const uploadVideos = async (uploads) => {
    setVideosLoaded(false);
    const params = {
      teamId: studentDetails.teamId,
      studentId: studentDetails.studentId,
    };
    const uploadProgress = (prog) => {
      setProgress((prog.loaded / prog.total) * 100);
    };
    const promises = uploads.map(
      async (file) =>
        new Promise((resolve, reject) => {
          requestUploadVideoLink(params)
            .then((link) => {
              setVideoUploaded(false);

              VidStorageLib(link, file, uploadProgress).then((url) => {
                setVideoUploaded(true);
                setProgress(0);
                resolve(url);
              });
            })
            .catch((e) => {
              reject(e);
            });
        })
    );
    await Promise.all(promises)
      .then((urls) => {
        const updateParams = {
          studentId: studentDetails.studentId,
          teamId: studentDetails.teamId,
          videos: urls.concat(currentApplication.videos),
        };
        updateSubmission(updateParams).then(() => {
          const newParams = {
            studentId: studentDetails.studentId,
            teamId: studentDetails.teamId,
          };
          getSubmission(newParams).then((newApplication) => {
            getVideos(newParams, newApplication.videos || []);
          });
        });
        showAlert('Files uploaded successfully!', 'success');
      })
      .catch((e) => {
        showAlert('There was a problem uploading your files', 'error', e);
      });
  };

  const renderPictures = () => {
    const allPhotos =
      attachments.length === attachmentKeys.length
        ? attachments.map((key, index) => ({
            key: attachmentKeys[index],
            component: (
              <img
                src={key.replace('https://192.168.65.2', 'http://localhost')}
                alt="Attachment"
                style={!imagesLoaded ? { visibility: 'hidden' } : { maxHeight: '300px' }}
                onLoad={() => {
                  setImagesLoaded(true);
                }}
              />
            ),
          }))
        : [];
    return allPhotos;
  };

  const renderVideos = () => {
    const allVideos =
      videos.length === videoKeys.length
        ? videos.map((key, index) => ({
            key: videoKeys[index],
            component: (
              <ReactPlayer
                width="100%"
                onReady={() => setVideosLoaded(true)}
                controls
                url={key.replace('https://192.168.65.2', 'http://localhost')}
              />
            ),
          }))
        : [];
    return allVideos;
  };

  const handleStartTour = (event) => {
    event.preventDefault();
    setTour(true);
  };

  const handleCallback = (cbProps) => {
    const { status, action } = cbProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED];
    if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
      setTour(false);
    }
  };
  /*
  const teamTourSteps = [
    {
      target: "#outlined-team-code",
      content: (
        <Typography>
          If you want to join a team, ask you friend for their code and put it
          in here.
        </Typography>
      ),
    },
    {
      target: "#join-team-button",
      content: (
        <Typography>
          After typing in your friend&apos;s code click here to change team.
        </Typography>
      ),
    },
    {
      target: "#revert-team-button",
      content: (
        <Typography>
          Click this button to go back to the team you were given at the very
          beginning.
        </Typography>
      ),
    },
    {
      target: "#team-code-text",
      content: (
        <Typography>
          This is your team code. Give it to your friends if you want them to
          join. Remember, a maximum of 3 students are allowed per team
        </Typography>
      ),
    },
    {
      target: "#team-members",
      content: <Typography>These are your current team mates!</Typography>,
    },
  ];
  */
  const tourSteps = [
    {
      placement: 'center',
      target: 'body',
      content: (
        <Typography>
          This is where you control all parts of your project! Make sure to fill in all the sections
          as best as possbile. If you want you can even add a video explaining your project!
        </Typography>
      ),
    },
    {
      target: '#application-sections',
      content: <Typography>Use this menu to jump to the different sections</Typography>,
    },
    {
      target: '#countdown-container',
      content: (
        <Typography>
          This countdown is set for the deadline after which you cannot change anything! Make sure
          you fill in everything and Save by then!
        </Typography>
      ),
    },
    {
      target: '#save-button',
      content: <Typography>Don&apos;t forget to save after making any changes</Typography>,
    },
  ];

  if (!studentDetails) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Box display="flex" width="100%" marginX="10px" flexDirection={matches ? 'column ' : 'row'}>
      <Joyride
        callback={handleCallback}
        steps={tourSteps}
        continuous
        run={tour}
        scrollToFirstStep
        showProgress
        showSkipButton
      />
      <Backdrop className={classes.backdrop} open={!videoUploaded}>
        <CircularProgressWithLabel color="inherit" value={progress} />
      </Backdrop>
      <Box display="flex" overflow="hidden" flexDirection={matches ? 'row ' : 'column'}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img height="150px" width="150px" alt="Superboy" src="/superboy.png" />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 0);
              }}
            >
              Team Settings
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 1);
              }}
            >
              Title
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 2);
              }}
            >
              Summary
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 3);
              }}
            >
              Why you did it
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 4);
              }}
            >
              How you did it
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 5);
              }}
            >
              What you found
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 6);
              }}
            >
              Can you explain it?
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 7);
              }}
            >
              Images
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                handleChange(event, 8);
              }}
            >
              Videos
            </MenuItem>
          </Menu>
          {matches ? (
            <Button
              id="application-sections"
              color="primary"
              variant="contained"
              aria-controls="application-sections"
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              Go To Section...
            </Button>
          ) : (
            <Tabs
              id="application-sections"
              variant="scrollable"
              scrollButtons={matches ? 'on' : 'auto'}
              orientation={matches ? 'horizontal ' : 'vertical'}
              value={value}
              onChange={handleChange}
              aria-label="Application tabs"
            >
              <Tab label="Team Settings" {...a11yProps(0)} />
              <Tab label="Title" {...a11yProps(1)} />
              <Tab label="Summary" {...a11yProps(2)} />
              <Tab label="Why you did it" {...a11yProps(3)} />
              <Tab label="How you did it" {...a11yProps(4)} />
              <Tab label="What you found" {...a11yProps(5)} />
              <Tab label="Can you explain it?" {...a11yProps(6)} />
              <Tab label="Images" {...a11yProps(7)} />
              <Tab label="Videos" {...a11yProps(8)} />
            </Tabs>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" width={matches ? '100% ' : '80%'}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={matches ? 'column ' : 'row'}
        >
          <Box
            id="countdown-container"
            flexDirection={matches ? 'column' : 'row'}
            display="flex"
            justifyContent="flex-start"
            marginBottom="20px"
            marginLeft="20px"
          >
            <Typography display={matches ? 'block' : 'inline'} align="center" variant="h5">
              Deadline:
            </Typography>
            <Countdown date={endDate} />
          </Box>
          <Box display="flex" justifyContent="flex-end" marginBottom="20px" marginRight="20px">
            <Button
              id="save-button"
              disabled={everythingDisabled}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={saveChanges}
            >
              Save
            </Button>
            <Box paddingX="10px" />
            <Button variant="contained" color="secondary" onClick={handleStartTour}>
              Show me how
            </Button>
          </Box>
        </Box>
        <Box display="flex" marginX="20px">
          <TabPanel value={value} index={0} classes={classes}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              minHeight="400px"
            >
              <Box>
                <Box marginY="10px">
                  <Typography variant="h4" align="center">
                    Control your team settings
                  </Typography>
                </Box>
                <Box margin="20px" display="flex" justifyContent="center">
                  <Typography variant="body1" align="center">
                    If you are participating by yourself, don&apos;t worry about this; You can
                    continue to the next section. If you are part of a team or want to be then the
                    code below is your team code. If you are the team leader, give this code to your
                    friends so that they can replace their own code with this one. Then, all
                    participants in the same team will have the same code. If you already have a
                    code from one of your friends then put this in the box below and click
                    &apos;Change team&apos;.
                  </Typography>
                </Box>
              </Box>
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={2}
                direction={matches ? 'column' : 'row'}
              >
                <Grid item sm spacing={2}>
                  <Box
                    justifyContent="space-evenly"
                    height="200px"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    marginLeft="10px"
                  >
                    <Typography variant="h6" align="center">
                      Join Another Team
                    </Typography>
                    <TextField
                      disabled={everythingDisabled}
                      fullWidth
                      id="outlined-team-code"
                      label="Your friend's team code"
                      value={newTeam}
                      onChange={(event) => setNewTeam(event.target.value)}
                      variant="outlined"
                    />
                    <Button
                      id="change-team-button"
                      disabled={everythingDisabled}
                      variant="contained"
                      color="primary"
                      startIcon={<GroupIcon />}
                      onClick={onChangeTeam}
                    >
                      Change Team
                    </Button>
                    <Button
                      id="revert-team-button"
                      disabled={
                        everythingDisabled ||
                        (studentDetails && studentDetails.teamId === studentDetails.oldTeamId)
                      }
                      variant="contained"
                      color="secondary"
                      startIcon={<HistoryIcon />}
                      onClick={onRevertTeam}
                    >
                      Go back to original team
                    </Button>
                  </Box>
                </Grid>
                <Divider orientation={matches ? 'horizontal' : 'vertical'} flexItem />
                <Grid item sm>
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box display="flex" flexDirection="row">
                      <Typography variant="h6" align="center">
                        Current Team Code:
                      </Typography>
                      <Box px="5px" />
                      <Typography id="team-code-text" variant="h5">
                        {studentDetails ? studentDetails.teamId : ''}
                      </Typography>
                    </Box>
                    <Typography variant="h6">Team Members</Typography>
                    <List id="team-members">
                      {studentDetails &&
                        studentDetails.teamMembers &&
                        studentDetails.teamMembers.map((member) => (
                          <ListItem key={uuid()}>
                            <ListItemAvatar>
                              <Avatar>
                                <EmojiObjectsIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${member.name} ${member.surname}`} />
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} classes={classes}>
            <ApplicationSection
              titleSection
              textDisabled={everythingDisabled}
              value={title}
              onChange={setTitle}
              title="What is your project called?"
              subtitle="Give an interesting but descriptive name to your project!"
              questions={[]}
            />
          </TabPanel>
          <TabPanel value={value} index={2} classes={classes}>
            <ApplicationSection
              textDisabled={everythingDisabled}
              value={abstract}
              onChange={setAbstract}
              title="What is your project about?"
              subtitle="Here, we need you to write a very short summary of what your project is about. This will repeat from some of the other sections
          since it is supposed to be a general idea of the whole project."
              questions={[
                'What made you curious enough to make a project about it?',
                'How did you find your answers? Were they what you expected?',
                ' If you had to do it again, what would you change in your method? What did you enjoy best about your project?',
              ]}
            />
          </TabPanel>
          <TabPanel value={value} index={3} classes={classes}>
            <ApplicationSection
              textDisabled={everythingDisabled}
              value={aims}
              onChange={setAims}
              title="Why did you do it?"
              subtitle="Here, you can explain why you decided to create a project about the topic you chose."
              questions={[
                'What were the questions you tried to answer?',
                'What did you expect the answer/s to be at the end of your project?',
              ]}
            />
          </TabPanel>
          <TabPanel value={value} index={4} classes={classes}>
            <ApplicationSection
              textDisabled={everythingDisabled}
              value={method}
              onChange={setMethod}
              title="How did you do it?"
              subtitle="Here, you can explain the steps you took to make your project."
              questions={[
                'How did you carry out your research? Did you use books, internet or both?',
                'What materials and objects did you need to make your project?',
                'If you carried out an experiment, what steps did you take? If it was building something what were the steps needed?',
              ]}
            />
          </TabPanel>
          <TabPanel value={value} index={5} classes={classes}>
            <ApplicationSection
              textDisabled={everythingDisabled}
              value={results}
              onChange={setResults}
              title="What did you find?"
              subtitle="Here, you can talk about the results of your project"
              questions={[
                'What answer/s did you obtain to your original questions?',
                'If you were building something, was the final product as good as you wished?',
                'If you were carrying out research about a particular person, what did you find out about them?',
              ]}
            />
          </TabPanel>
          <TabPanel value={value} index={6} classes={classes}>
            <ApplicationSection
              textDisabled={everythingDisabled}
              value={discussion}
              onChange={setDiscussion}
              title="Can you explain your results?"
              subtitle="Here, you can explain your results from the other section"
              questions={[
                'How do your results compare to research you made before starting?',
                'What went well in your project?',
                'What would you do differently if you had to repeat your project?',
              ]}
            />
          </TabPanel>
          <TabPanel value={value} index={7} classes={classes}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              minHeight="400px"
            >
              <Box marginY="10px">
                <Typography variant="h4" align="center">
                  Your images
                </Typography>
              </Box>
              <Box margin="20px" display="flex" justifyContent="center">
                <Typography variant="body1" align="center">
                  If you have any images associated with your project please upload them here. We
                  appreciate any kind of images eg. if there are pictures of you building a
                  prototype or maybe a diagram explaining your project or a relevant drawing.
                  Anything that helps us better understand your project and results we encourage you
                  to put here.
                </Typography>
              </Box>
              <Button
                disabled={everythingDisabled}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpenUploadImages(true);
                }}
              >
                Add Images
                <AddIcon />
              </Button>
              <Box className={classes.gridListContainer} marginY="20px">
                <GridList className={classes.gridList} cellHeight={300} cols={matches ? 1 : 3}>
                  {!imagesLoaded
                    ? attachmentKeys.map((key) => (
                        <GridListTile key={key} cols={1}>
                          <CircularProgress color="inherit" />
                          <Typography>Loading your images...</Typography>
                        </GridListTile>
                      ))
                    : null}
                  {renderPictures().map((tile, index) => (
                    <GridListTile key={tile.key} cols={1}>
                      {tile.component}
                      <GridListTileBar
                        title={`Image ${index + 1}`}
                        actionIcon={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <IconButton
                            disabled={everythingDisabled}
                            onClick={() => {
                              removeDocument(tile.key);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      />
                    </GridListTile>
                  ))}
                </GridList>
              </Box>
              <DropzoneDialog
                open={openUploadImages}
                dialogTitle="Add Images"
                onSave={(uploads) => {
                  uploadAttachments(uploads);
                  setOpenUploadImages(false);
                }}
                showFileNames={false}
                showFileNamesInPreview={false}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                showPreviews
                maxFileSize={10000000}
                onClose={() => {
                  setOpenUploadImages(false);
                }}
              />
              <Box />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={8} classes={classes}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              minHeight="400px"
            >
              <Box marginY="10px">
                <Typography variant="h4" align="center">
                  Your videos
                </Typography>
              </Box>
              <Box margin="20px" display="flex" justifyContent="center">
                <Typography variant="body1" align="center">
                  If you have any videos associated with your project please upload them here. We
                  appreciate any kind of videos eg. if there are videos of you building a prototype
                  or you explaining your project a bit more in detail. Remember that this video will
                  be made available to the public while voting is open (if your parents have all
                  allowed us to show your videos/pictures online). Videos should be in MP4 format.
                </Typography>
              </Box>
              <Button
                disabled={everythingDisabled}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpenUploadVideos(true);
                }}
              >
                Add Videos
                <AddIcon />
              </Button>
              <Box
                marginY="20px"
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {!videosLoaded
                  ? videoKeys.map((key) => (
                      <Box key={key}>
                        <CircularProgress color="inherit" />
                        <Typography>Loading your videos...</Typography>
                      </Box>
                    ))
                  : null}
                {renderVideos().map((tile, index) => (
                  <Box key={tile.key} display="flex" flexDirection="column" margin="10px">
                    {tile.component}
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      bgcolor="text.disabled"
                      textAlign="center"
                    >
                      <Typography
                        display="inline"
                        variant="h6"
                        justify="center"
                        color="textPrimary"
                      >
                        Video
                      </Typography>
                      <Box paddingX="5px" />
                      <Typography
                        display="inline"
                        variant="h6"
                        justify="center"
                        color="textPrimary"
                      >
                        {index}
                      </Typography>
                      <IconButton
                        disabled={everythingDisabled}
                        onClick={() => {
                          handleRemoveVideo(tile.key);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
              <DropzoneDialog
                open={openUploadVideos}
                dialogTitle="Add Videos"
                onSave={(uploads) => {
                  uploadVideos(uploads);
                  setOpenUploadVideos(false);
                }}
                showFileNames={false}
                showFileNamesInPreview={false}
                acceptedFiles={['video/*']}
                showPreviews
                maxFileSize={100000000}
                onClose={() => {
                  setOpenUploadVideos(false);
                }}
              />
              <Box />
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}
