import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import * as Sentry from '@sentry/browser';
import { Typography } from '@material-ui/core';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Box display="flex" justifyContent="center">
          <Box width="500px">
            <Alert severity="error" variant="filled">
              <AlertTitle>Something went wrong!</AlertTitle>
              <Typography>
                Something went wrong in loading this page&apos;s content. Please return to the
                homepage.
              </Typography>
            </Alert>
          </Box>
        </Box>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};
