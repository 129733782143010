import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, Box } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import { CookiesProvider } from 'react-cookie';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './Routes';
import { AuthProvider } from './contexts/Authentication';
import ErrorBoundary from './boundaries/Error';
import Alert from './components/Alert';
import { AlertProvider } from './contexts/Alert';
import { UserProvider } from './contexts/User';
import EventsBanner from './components/EventsBanner';

export default function App() {
  return (
    <Router>
      <CssBaseline />
      <ErrorBoundary>
        <CookiesProvider>
          <AlertProvider>
            <AuthProvider>
              <UserProvider>
                <EventsBanner />
                <Header />
                <Box marginTop="20px">
                  <Routes />
                </Box>
                <Alert />
                <Footer />
                <CookieConsent
                  location="bottom"
                  buttonText="I understand"
                  cookieName="superherosciencecprojectcookieconsent"
                  style={{ background: '#2B373B' }}
                  buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
                  expires={150}
                  sameSite="strict"
                >
                  This website uses cookies necessary to ensure project fairness and to monitor
                  website performance. No cookies are passed to third parties or used for marketing
                  purposes.
                </CookieConsent>
              </UserProvider>
            </AuthProvider>
          </AlertProvider>
        </CookiesProvider>
      </ErrorBoundary>
    </Router>
  );
}
