import { useCallback } from 'react';
import ReactGA from 'react-ga';
import { TYPES } from './reducer';
import { useAlertActions } from '../Alert';

const useActions = (service, state, dispatch, history) => {
  const { cognitoUserObject } = state;
  const { showAlert } = useAlertActions();
  const logout = useCallback(
    async (changeUser) => {
      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: true,
      });
      await service
        .signOut({ global: true })
        .then(() => {
          dispatch({
            type: TYPES.setIsAuthenticated,
            payload: false,
          });
          changeUser();
          dispatch({
            type: TYPES.setCognitoUserObject,
            payload: null,
          });
          dispatch({
            type: TYPES.setIdentityUserObject,
            payload: null,
          });
        })
        .catch((err) => {
          showAlert('There was a problem signing out', 'error', err);
        });

      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: false,
      });
      history.push('/login');
    },
    [service, dispatch, history, showAlert]
  );

  const refreshSession = useCallback(async () => {
    dispatch({
      type: TYPES.setIsAuthenticating,
      payload: true,
    });
    await service
      .currentAuthenticatedUser({
        bypassCache: true,
      })
      .then(async (user) => {
        await service
          .currentCredentials()
          .then((credentials) => {
            dispatch({
              type: TYPES.setIdentityUserObject,
              payload: credentials.identityId,
            });
          })
          .catch();
        dispatch({
          type: TYPES.setIsAuthenticated,
          payload: true,
        });
        dispatch({
          type: TYPES.setCognitoUserObject,
          payload: user,
        });
      })
      .catch(() => {
        dispatch({
          type: TYPES.setIsAuthenticated,
          payload: false,
        });
      });
    dispatch({
      type: TYPES.setIsAuthenticating,
      payload: false,
    });
  }, [service, dispatch]);

  const login = useCallback(
    async (email, password) => {
      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: true,
      });
      await service
        .signIn(email, password)
        .then((user) => {
          dispatch({
            type: TYPES.setCognitoUserObject,
            payload: user,
          });
          dispatch({
            type: TYPES.setIsAuthenticating,
            payload: false,
          });
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            history.push('/reset-password/');
          }
          dispatch({
            type: TYPES.setIsAuthenticated,
            payload: true,
          });
          ReactGA.set({
            userId: user.attributes.sub,
          });
          history.push('/dashboard');
        })
        .catch((err) => {
          dispatch({
            type: TYPES.setIsAuthenticating,
            payload: false,
          });
          if (err.code === 'UserNotConfirmedException') {
            history.push(`/confirm-user/${email}`);
          } else if (err.code === 'NotAuthorizedException') {
            showAlert('Incorrect username or password!', 'warning', err);
          } else if (err.code === 'PasswordResetRequiredException') {
            history.push(`/reset-password/forgot/${email}`);
          } else {
            showAlert('There was a problem signing in', 'error', err);
          }
        });

      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: false,
      });
    },
    [service, dispatch, history, showAlert]
  );

  const signup = useCallback(
    async (userType, email, password) => {
      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: true,
      });
      await service
        .signUp({
          username: email,
          password,
          attributes: {
            'custom:isParent': userType === 'parent' ? 'true' : 'false',
          },
        })
        .then(() => {
          showAlert('Great stuff! You have successfully been registered.', 'success');
          dispatch({
            type: TYPES.setIsAuthenticating,
            payload: false,
          });
          history.push(`/confirm-user/${email}`);
        })
        .catch((err) => {
          if (err.code === 'UsernameExistsException') {
            showAlert(
              'This email is already used! Make sure parent and student have different emails.',
              'warning',
              err
            );
          } else {
            showAlert('There was a problem signing up', 'error', err);
          }
        });

      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: false,
      });
    },
    [service, dispatch, history, showAlert]
  );

  const confirmSignup = useCallback(
    async (email, code, password) => {
      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: true,
      });
      if (password) {
        await service
          .forgotPasswordSubmit(email, code, password)
          .then(async () => {
            dispatch({
              type: TYPES.setIsAuthenticated,
              payload: true,
            });
            login(email, password);
          })
          .catch((err) => {
            if (err.code === 'CodeMismatchException') {
              showAlert('This code is wrong!', 'error', err);
            } else if (err.code === 'ExpiredCodeException') {
              showAlert('This code is expired!', 'error', err);
            }
            showAlert('There was a problem confirming your account', 'error', err);
          });
      } else {
        await service
          .confirmSignUp(email, code)
          .then(() => {
            dispatch({
              type: TYPES.setIsAuthenticated,
              payload: false,
            });
            dispatch({
              type: TYPES.setIsAuthenticating,
              payload: false,
            });
            showAlert("Well done! You've been confirmed.", 'success');
            history.push('/login');
          })
          .catch((err) => {
            if (err.code === 'CodeMismatchException') {
              showAlert('This code is wrong!', 'error', err);
            } else if (err.code === 'ExpiredCodeException') {
              showAlert('This code is expired!', 'error', err);
            }
            showAlert('There was a problem confirming your account', 'error', err);
          });
      }

      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: false,
      });
    },
    [service, dispatch, history, login, showAlert]
  );

  const resetPassword = useCallback(
    async (email, password) => {
      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: true,
      });
      if (password) {
        await service
          .completeNewPassword(cognitoUserObject, password)
          .then(() => {
            dispatch({
              type: TYPES.setIsAuthenticated,
              payload: true,
            });
            dispatch({
              type: TYPES.setIsAuthenticating,
              payload: false,
            });
            history.push('/dashboard');
          })
          .catch((err) => {
            if (err.code === 'UserNotFoundException') {
              showAlert(
                'This email has not been registered. Do you want to sign up instead?',
                'error',
                err
              );
            } else if (err.code === 'UserNotConfirmedException') {
              showAlert('This email has not been confirmed yet.', 'error', err);
            }
            showAlert('There was a problem confirming your new password', 'error', err);
          });
      } else {
        await service
          .forgotPassword(email)
          .then(() => {
            showAlert('Code sent to email!', 'success');
            history.push(`/reset-password/forgot/${email}`);
          })
          .catch((err) => {
            if (err.code === 'UserNotFoundException') {
              showAlert(
                'This email has not been registered. Do you want to sign up instead?',
                'error',
                err
              );
            } else if (err.code === 'UserNotConfirmedException') {
              showAlert('This email has not been confirmed yet.', 'error', err);
            }
            showAlert('There was a problem confirming requesting a new password', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsAuthenticating,
        payload: false,
      });
    },
    [service, dispatch, cognitoUserObject, history, showAlert]
  );

  return {
    logout,
    login,
    signup,
    confirmSignup,
    resetPassword,
    refreshSession,
  };
};

export default useActions;
