const initialState = {
  isAuthenticating: false,
  isAuthenticated: false,
  cognitoUserObject: null,
  identityUserObject: null,
};

const TYPES = {
  setIsAuthenticating: 'SET_IS_AUTHENTICATING',
  setIsAuthenticated: 'SET_IS_AUTHENTICATED',
  setCognitoUserObject: 'SET_COGNITO_USER_OBJECT',
  setIdentityUserObject: 'SET_IDENTITY_USER_OBJECT',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.setIsAuthenticating:
      return {
        ...state,
        isAuthenticating: action.payload,
      };
    case TYPES.setIsAuthenticated:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case TYPES.setCognitoUserObject:
      return {
        ...state,
        cognitoUserObject: action.payload,
      };
    case TYPES.setIdentityUserObject:
      return {
        ...state,
        identityUserObject: action.payload,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
export { initialState, TYPES, reducer };
