import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import useActions from './actions';
import { reducer, initialState } from './reducer';

const UserStateContext = createContext();
const UserActionsContext = createContext();

function UserProvider({ children }) {
  const service = API;
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(service, state, dispatch);

  return (
    <UserActionsContext.Provider value={actions}>
      <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>
    </UserActionsContext.Provider>
  );
}
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useUser = () => useContext(UserStateContext);
const useUserActions = () => useContext(UserActionsContext);
export { UserProvider, useUser, useUserActions };
