// To do: Add useEffect to obtain list of children, then use that to get
// the childs status and parent email. Also add the api call to update the
// children when the button is pressed. Before this one must check if the
// student's parent email matches this parent email also check if the student
// has already been added!. Finally add in the dynamic
// list of children with icon/disabled based on activeStep. Also will have to
// figure out if to make a separate link when a student is clicked such as
// application/studentId. This will then check if the student is confirmed and if so
// display the application otherwise display the induction.

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Typography, Box, Button, CircularProgress } from '@material-ui/core';
import Joyride, { STATUS } from 'react-joyride';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import { useAuth } from '../contexts/Authentication';
import { useUser, useUserActions } from '../contexts/User';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function ParentDashboard() {
  const [studentId, setStudentId] = useState(' ');
  const [listItems, setListItems] = useState([]);
  const [tour, setTour] = useState(false);
  const { cognitoUserObject } = useAuth();
  const { currentUser, isLoading } = useUser();
  const { getDetails, addDetails, getStudentDetails } = useUserActions();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  let username;
  let email;
  if (cognitoUserObject) {
    username = cognitoUserObject.username;
    email = cognitoUserObject.attributes.email;
  }
  // Here this is the parents activeStep
  const getAppropriateListFeatures = (confirmed, activeStep) => {
    if (confirmed === 'rejected') {
      return {
        icon: <ThumbDownIcon />,
        text: 'A moderator has rejected your previous application - click here to resubmit your details!',
        disabled: false,
        color: 'secondary',
      };
    }
    if (confirmed === 'true') {
      return {
        icon: <CheckIcon />,
        text: 'Confirmed and has started their project - click here to view!',
        disabled: false,
        color: 'primary',
      };
    }
    if (activeStep === '3') {
      return {
        icon: <HourglassFullIcon />,
        text: 'Pending our review - come back later!',
        disabled: true,
        color: 'secondary',
      };
    }
    return {
      icon: <CreateIcon />,
      text: 'We still need some details from you - click here to continue!',
      disabled: false,
      color: 'primary',
    };
  };

  useEffect(() => {
    const getEachStudentApplication = async (currentStudent) => {
      const result = getStudentDetails({
        studentId: currentStudent.student,
        isParent: cognitoUserObject.attributes['custom:isParent'],
      }).then((response) => response);
      return result;
    };

    const getAllStudentApplications = async (currentApplications) => {
      const promises = currentApplications.map(getEachStudentApplication);
      await Promise.all(promises).then((allDetails) => {
        setListItems(
          allDetails.map((details, index) => {
            const listFeatures = getAppropriateListFeatures(
              details.confirmed,
              currentApplications[index].step
            );
            return (
              <ListItem
                button
                id={`application-${index}`}
                disabled={listFeatures.disabled}
                key={details.studentId}
                onClick={() => {
                  if (details.confirmed === 'true') {
                    getStudentDetails({
                      studentId: details.studentId,
                      isParent: true,
                    }).then(() => {
                      history.push(`project`);
                    });
                  } else {
                    history.push(`application/${details.studentId}`);
                  }
                }}
              >
                <ListItemIcon id={`list-item-icon-${index}`}>{listFeatures.icon}</ListItemIcon>
                <ListItemText
                  primary={`${details.student_name} ${details.surname}`}
                  secondaryTypographyProps={listFeatures.color}
                  secondary={listFeatures.text}
                />
              </ListItem>
            );
          })
        );
      });
    };
    if (!currentUser || !Array.isArray(currentUser)) {
      getDetails();
    } else if (currentUser && Array.isArray(currentUser)) {
      const currentApplications = currentUser.map((student) => ({
        student: student.studentId,
        step: student.induction_step,
      }));
      if (currentApplications.length !== listItems.length) {
        getAllStudentApplications(currentApplications);
      }
    }
  }, [currentUser, cognitoUserObject, getStudentDetails, getDetails, history, listItems]);

  const handleNewStudent = () => {
    const params = {
      parentId: username,
      studentId: studentId.trim(),
      email,
    };
    // Add checks if student already registered as monitored by them. Later move this to API
    addDetails(params).then(() => {
      getDetails();
    });
  };

  const handleStartTour = (event) => {
    event.preventDefault();
    setTour(true);
  };

  const handleCallback = (cbProps) => {
    const { status } = cbProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setTour(false);
    }
  };

  const steps = [
    {
      placement: 'center',
      target: 'body',
      content: (
        <Typography>
          This page is where you control all your students applications. You can add new students
          with their codes or monitor ongoing applications. Through this page you can check in on
          your student&apos;s application progress.
        </Typography>
      ),
    },
    {
      target: '#outlined-code-input',
      content: (
        <Typography>
          When a student registers they give us a parent email. Check your email (even spam) where
          you should have received a code. Input the code here to connect the student&apos;s account
          to yours. If you are sure the students gave us your email and have not received an email
          yet, let us know.
        </Typography>
      ),
    },
    {
      target: '#student-code-submit-button',
      content: (
        <Typography>
          Once you have inputted the emailed code, click here to add the student to your account.
        </Typography>
      ),
    },
    {
      target: '#previously-added-applications',
      content: <Typography>Here you can see your previously added student accounts.</Typography>,
    },
    {
      target: '#list-item-icon-0',
      content: (
        <Typography>
          Near each student application there will be a status of the application telling you if we
          are waiting for you to do something or if you need to wait while we check your details.
        </Typography>
      ),
    },
    {
      target: '#application-0',
      content: <Typography>Click on any application to jump in.</Typography>,
    },
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      margin="10px"
    >
      <Joyride
        callback={handleCallback}
        steps={steps}
        continuous
        run={tour}
        scrollToFirstStep
        showProgress
        showSkipButton
      />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button variant="contained" color="secondary" onClick={handleStartTour}>
          Show me how
        </Button>
      </Box>
      <Box marginBottom="20px">
        <Typography id="page-title" variant="h3" align="center" gutterbottom>
          Your Dashboard
        </Typography>
      </Box>

      <Grid
        xs
        container
        justify="center"
        alignItems="center"
        spacing="2"
        direction={matches ? 'column' : 'row'}
      >
        <Grid item xs>
          <Typography variant="h5" align="center" gutterBottom>
            Add a new student account to monitor
          </Typography>
          <Box display="flex" flexDirection="column">
            <Box marginBottom="10px" width="100%">
              <TextField
                fullWidth
                required
                id="outlined-code-input"
                label="Student Code"
                type="text"
                helperText="This is the code you receive by email after your student registers with their own email account. It is 32 numbers and letters separated by dashes."
                autoComplete="current-code"
                variant="outlined"
                value={studentId}
                onChange={(event) => setStudentId(event.target.value)}
              />
            </Box>
            <Button
              id="student-code-submit-button"
              disabled={studentId.trim().length === 0}
              variant="contained"
              color="primary"
              onClick={handleNewStudent}
            >
              Add
            </Button>
          </Box>
        </Grid>
        <Divider orientation={matches ? 'horizontal' : 'vertical'} flexItem />
        <Grid item xs>
          <Typography variant="h5" align="center" id="previously-added-applications">
            View your student&apos;s application
          </Typography>
          <Box display="flex" flexDirection="column" height="300px">
            <List component="nav" aria-label="current applications">
              {listItems}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
