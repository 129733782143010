import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import useActions from './actions';
import { reducer, initialState } from './reducer';

const AuthStateContext = createContext();
const AuthActionsContext = createContext();

function AuthProvider({ children }) {
  const service = Auth;
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(service, state, dispatch, history);
  const { refreshSession } = actions;
  useEffect(() => {
    refreshSession();
  }, [refreshSession]);

  return (
    <AuthActionsContext.Provider value={actions}>
      <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
    </AuthActionsContext.Provider>
  );
}
AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
const useAuth = () => useContext(AuthStateContext);
const useAuthActions = () => useContext(AuthActionsContext);
export { AuthProvider, useAuth, useAuthActions };
