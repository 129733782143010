/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import uuid from 'react-uuid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  media: {
    height: '300px',
  },
  starWars: { border: '1px solid rgb(14, 105, 18)', color: 'rgb(14, 105, 18)' },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  subheader: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function ProjectIdea(props) {
  const { title, subheaders, imageLink, imageTitle, description, links, superheroes, starWars } =
    // eslint-disable-next-line react/destructuring-assignment
    props.children;
  const classes = useStyles();
  return [
    <Button
      size="large"
      variant="contained"
      color="primary"
      onClick={(event) => {
        event.preventDefault();
        props.setCurrentProject(null);
      }}
      startIcon={<ArrowBackIcon />}
    >
      See More Projects
    </Button>,
    <Box paddingY="20px" />,
    <Card className={classes.root}>
      <CardHeader
        classes={{ subheader: classes.subheader }}
        title={title}
        titleTypographyProps={{ align: 'center' }}
        subheader={[
          subheaders
            .map((subheader) => (
              <Chip key={subheader} label={subheader} color="primary" variant="outlined" />
            ))
            .concat(
              superheroes.map((superhero) => (
                <Chip
                  key={superhero}
                  label={superhero.name}
                  classes={starWars ? { root: classes.starWars } : undefined}
                  color="secondary"
                  variant="outlined"
                />
              ))
            ),
        ]}
      />
      {imageTitle && <CardMedia className={classes.media} image={imageLink} title={imageTitle} />}
      <CardContent>
        <Typography variant="body1" color="textPrimary">
          Project:
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {description}
        </Typography>
        <Box paddingY="20px" />
        <Typography variant="body1" color="textPrimary">
          About the
          {starWars ? ' star wars phenomenon:' : ' superheroes:'}
        </Typography>

        <List>
          {superheroes &&
            superheroes.map((superhero) => (
              <ListItem key={uuid()}>
                {superhero.avatar && (
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                )}
                <ListItemText primary={superhero.name} secondary={superhero.description} />
              </ListItem>
            ))}
        </List>
        {links && (
          <Typography variant="body1" color="textPrimary">
            Useful Resources:
          </Typography>
        )}
        {links &&
          links.map((link) => (
            <Typography variant="body1" color="textSecondary" component="p">
              {link}
            </Typography>
          ))}
      </CardContent>
    </Card>,
  ];
}
ProjectIdea.propTypes = {
  title: PropTypes.string.isRequired,
  subheaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  starWars: PropTypes.bool,
  imageLink: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string.isRequired),
  superheroes: PropTypes.arrayOf(
    PropTypes.objectOf({
      name: PropTypes.string.isRequired,
      avatar: PropTypes.node,
      description: PropTypes.string.isRequired,
    })
  ),
};
ProjectIdea.defaultProps = {
  starWars: false,
  links: undefined,
  superheroes: undefined,
};
