import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import uuid from 'react-uuid';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
  chipRoot: {
    padding: '30px',
  },
  chipLabel: {
    whiteSpace: 'normal',
  },
}));

export default function ApplicationSection(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { title, titleSection, subtitle, questions, value, onChange, textDisabled } = props;

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" minHeight="400px">
      <Box marginY="5px">
        <Typography variant="h4" align="center">
          {title}
        </Typography>
      </Box>
      <Box marginX="20px" display="flex" justifyContent="center">
        <Typography variant="body1" align="center">
          {subtitle}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {questions.map((q) => (
          <Box margin="10px" key={uuid()}>
            <Chip
              label={q}
              color="primary"
              classes={matches ? { root: classes.chipRoot, label: classes.chipLabel } : {}}
            />
          </Box>
        ))}
      </Box>
      <Box margin="10px">
        <Typography variant="h6" align="center">
          Don&apos;t forget to save before changing section or page!
        </Typography>
      </Box>
      <Box margin="10px">
        <TextField
          disabled={textDisabled}
          fullWidth
          id="outlined-multiline-static"
          label={titleSection ? "Your project's title" : 'Your answer'}
          multiline={!titleSection}
          rows={titleSection ? 2 : 10}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          variant="outlined"
        />
      </Box>
    </Box>
  );
}
ApplicationSection.propTypes = {
  titleSection: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  textDisabled: PropTypes.bool.isRequired,
};

ApplicationSection.defaultProps = {
  titleSection: false,
};
