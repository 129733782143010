import React, { useEffect } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box, Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth, useAuthActions } from '../../contexts/Authentication';
import { useUserActions, useUser } from '../../contexts/User';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
// HOC wrapper to secure pages
export default function withParentAuth(Page) {
  return () => {
    const { isAuthenticated, isAuthenticating } = useAuth();
    const { refreshSession } = useAuthActions();
    const { getDetails } = useUserActions();
    const classes = useStyles();
    const { isLoading } = useUser();
    useEffect(() => {
      refreshSession();
      getDetails();
    }, [refreshSession, getDetails]);
    if (isAuthenticating) {
      return (
        <Backdrop className={classes.backdrop} open={isAuthenticating}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }

    if (isAuthenticated && !isAuthenticating) {
      return (
        <>
          {isLoading ? (
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <></>
          )}
          <Page />
        </>
      );
    }

    if (!isAuthenticated && !isAuthenticating) {
      return (
        <Box display="flex" justifyContent="center">
          <Box width="300px">
            <Alert severity="warning" variant="filled">
              <AlertTitle>Page is Protected</AlertTitle>
              Please login to see this page!
            </Alert>
          </Box>
        </Box>
      );
    }
    return false;
  };
}
