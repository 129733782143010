const initialState = {
  showAlert: false,
  alertInfo: '',
  alertType: '',
};

const TYPES = {
  setAlert: 'SET_ALERT',
  setOpenAlert: 'SET_OPEN_ALERT',
  setAlertType: 'SET_ALERT_TYPE',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.setAlert:
      return {
        ...state,
        alertInfo: action.payload,
      };
    case TYPES.setAlertType:
      return {
        ...state,
        alertType: action.payload,
      };
    case TYPES.setOpenAlert:
      return {
        ...state,
        showAlert: action.payload,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
export { initialState, TYPES, reducer };
