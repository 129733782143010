import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './index.css';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const ENV = process.env.REACT_APP_ENV || 'local';
const SENTRY_RELEASE = process.env.REACT_APP_COMMIT_REF;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'student',
        endpoint: config.apiGateway.STUDENTS_URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'parent',
        endpoint: config.apiGateway.PARENTS_URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'team',
        endpoint: config.apiGateway.TEAMS_URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'public',
        endpoint: config.apiGateway.PUBLIC_URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});
const options = {
  debug: ENV === 'local',
  dsn: SENTRY_DSN,
  environment: ENV,
  release: SENTRY_RELEASE,
  attachStacktrace: true,
};

Sentry.init(options);

const trackingId = 'UA-171546288-1';
ReactGA.initialize(trackingId);
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
