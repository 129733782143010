const initialState = {
  isLoading: false,
  currentUser: null,
  currentApplication: null,
  attachments: [],
  videos: [],
  studentDetails: null,
};

const TYPES = {
  setIsLoading: 'SET_IS_LOADING',
  setCurrentUser: 'SET_CURRENT_USER',
  setCurrentApplication: 'SET_CURRENT_APPLICATION',
  setAttachments: 'SET_ATTACHMENTS',
  setVideos: 'SET_VIDEOS',
  setStudentDetails: 'SET_STUDENT_DETAILS',
  setTeamMembers: 'SET_TEAM_MEMBERS',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.setIsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case TYPES.setCurrentUser:
      return {
        ...state,
        currentUser: action.payload,
      };
    case TYPES.setCurrentApplication:
      return {
        ...state,
        currentApplication: action.payload,
      };
    case TYPES.setAttachments:
      return {
        ...state,
        attachments: action.payload,
      };
    case TYPES.setVideos:
      return {
        ...state,
        videos: action.payload,
      };
    case TYPES.setStudentDetails:
      return {
        ...state,
        studentDetails: action.payload,
      };
    case TYPES.setTeamMembers:
      return {
        ...state,
        studentDetails: {
          ...state.studentDetails,
          teamMembers: action.payload,
        },
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
export { initialState, TYPES, reducer };
