import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import useActions from './actions';
import { reducer, initialState } from './reducer';

const AlertStateContext = createContext();
const AlertActionsContext = createContext();

function AlertProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(dispatch);

  return (
    <AlertActionsContext.Provider value={actions}>
      <AlertStateContext.Provider value={state}>{children}</AlertStateContext.Provider>
    </AlertActionsContext.Provider>
  );
}
AlertProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useAlert = () => useContext(AlertStateContext);
const useAlertActions = () => useContext(AlertActionsContext);
export { AlertProvider, useAlert, useAlertActions };
