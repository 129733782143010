import { useCallback } from 'react';
import { TYPES } from './reducer';
import { useAlertActions } from '../Alert';
import { useAuth } from '../Authentication';

const useActions = (service, state, dispatch) => {
  const { showAlert } = useAlertActions();
  const { cognitoUserObject } = useAuth();
  let endpoint;
  let username = 'null';
  if (cognitoUserObject) {
    username = cognitoUserObject.username;
    endpoint = cognitoUserObject.attributes['custom:isParent'] === 'true' ? 'parent' : 'student';
  }
  const addDetails = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      await service
        .post(endpoint, `/${endpoint}/add`, {
          body: params,
        })
        .then(() => {
          if (endpoint === 'student') {
            showAlert('Details saved successfully!', 'success');
          } else {
            showAlert('Student account added successfully!', 'success');
          }
        })
        .catch((err) => {
          if (endpoint === 'student') {
            showAlert('There was a problem saving your details', 'error', err);
          } else {
            showAlert('There was a problem adding this student account', 'error', err);
          }
        });
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, endpoint, showAlert]
  );

  const updateDetails = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      await service
        .patch(endpoint, `/${endpoint}/update`, {
          body: params,
        })
        .then(async () => {
          if (endpoint === 'student') {
            if (params.parent_email.trim().length !== 0) {
              const emailParams = {
                parent_email: params.parent_email,
                studentId: params.studentId,
              };
              await service
                .post('student', '/student/add/parent', {
                  body: emailParams,
                })
                .then(() => {
                  showAlert('We sent an email to your legal guardian!', 'success');
                })
                .catch((err) => {
                  showAlert('There was a problem sending your parent an email', 'error', err);
                });
            } else {
              showAlert('Details saved!', 'success');
            }
          }
        })
        .catch((err) => {
          showAlert('There was a problem saving your details', 'error', err);
        });
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, endpoint, showAlert]
  );

  const getDetails = useCallback(async () => {
    dispatch({
      type: TYPES.setIsLoading,
      payload: true,
    });
    if (username !== 'null') {
      const queryStringParameters =
        endpoint === 'parent' ? { parentId: username, isParent: true } : { studentId: username };
      await service
        .get(endpoint, `/${endpoint}/get`, {
          queryStringParameters,
        })
        .then((response) => {
          dispatch({
            type: TYPES.setCurrentUser,
            payload: response,
          });
          if (endpoint === 'student') {
            dispatch({
              type: TYPES.setStudentDetails,
              payload: response,
            });
          }
        })
        .catch((err) => {
          if (!err.response.data || err.response.data.error !== 'Item not found.') {
            showAlert('There was a problem getting your details', 'error', err);
          } else if (err.response.data && err.response.data.error === 'Item not found.') {
            dispatch({
              type: TYPES.setCurrentUser,
              payload: 'Not Found',
            });
          }
        });
    }
    dispatch({
      type: TYPES.setIsLoading,
      payload: false,
    });
  }, [service, dispatch, username, showAlert, endpoint]);

  const getStudentDetails = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      const response = await service
        .get('student', '/student/get', {
          queryStringParameters: params,
        })
        .then((res) => {
          dispatch({
            type: TYPES.setStudentDetails,
            payload: res,
          });
          if (endpoint === 'student') {
            dispatch({
              type: TYPES.setCurrentUser,
              payload: response,
            });
          }
          return res;
        })
        .catch((err) => {
          showAlert('There was a problem getting your documents', 'error', err);
        });
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
      return response;
    },
    [service, dispatch, showAlert, endpoint]
  );

  const addStudentDocuments = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      const response = await service
        .patch('student', '/student/update', {
          body: params,
        })
        .then((res) => res)
        .catch((err) => {
          showAlert('There was a problem getting your documents', 'error', err);
        });
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
      return response;
    },
    [service, dispatch, showAlert]
  );

  const getSubmission = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      let result;
      if (username !== 'null') {
        result = await service
          .get('team', '/team/submission/get', {
            queryStringParameters: params,
          })
          .then((response) => {
            let subResponse = response;
            if (subResponse.length === 0) {
              subResponse = {};
            } else {
              // eslint-disable-next-line prefer-destructuring
              subResponse = response[0];
            }
            dispatch({
              type: TYPES.setCurrentApplication,
              payload: subResponse,
            });
            return subResponse;
          })
          .catch((err) => {
            if (!err.response.data || err.response.data.error !== 'Item not found.') {
              showAlert('There was a problem getting your project report details', 'error', err);
            }
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
      return result;
    },
    [service, dispatch, username, showAlert]
  );

  const updateSubmission = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      if (username !== 'null') {
        await service
          .patch('team', '/team/submission/update', {
            body: params,
          })
          .then(() => {
            showAlert('Project details saved successfully!', 'success');
          })
          .catch((err) => {
            if (!err.response.data || err.response.data.error !== 'Item not found.') {
              showAlert('There was a problem updating the project report details', 'error', err);
            }
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, username, showAlert]
  );

  const uploadAttachment = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      if (username !== 'null') {
        await service
          .put('team', '/team/attachments/add', {
            body: params,
          })
          .catch((err) => {
            showAlert('There was a problem uploading your files', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, username, showAlert]
  );

  const removeAttachment = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      if (username !== 'null') {
        await service
          .del('team', '/team/attachment/remove', {
            body: params,
          })
          .catch((err) => {
            showAlert('There was a problem remove your file', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, username, showAlert]
  );
  const getAttachments = useCallback(
    async (params, keys) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      if (username !== 'null') {
        const promises = keys.map((key) =>
          service.get('team', '/team/attachment/get', {
            queryStringParameters: { ...params, key },
          })
        );
        Promise.all(promises)
          .then((results) => {
            dispatch({
              type: TYPES.setAttachments,
              payload: results.map((result) => result.files),
            });
          })
          .catch((err) => {
            showAlert('There was a problem getting your files', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, username, showAlert]
  );

  const getTeamMembers = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      if (username !== 'null') {
        await service
          .get('team', '/team/members/get', {
            queryStringParameters: params,
          })
          .then((response) => {
            dispatch({
              type: TYPES.setTeamMembers,
              payload: response,
            });
          })
          .catch((err) => {
            showAlert('There was a problem getting your team members', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, username, showAlert]
  );

  const changeTeam = useCallback(
    async (teamId, studentId) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      if (username !== 'null') {
        await service
          .patch('team', '/team/join', {
            body: { teamId, studentId },
          })
          .then(async () => {
            await getStudentDetails({
              studentId,
              isParent: endpoint === 'parent',
            });
            dispatch({
              type: TYPES.setCurrentApplication,
              payload: null,
            });
            dispatch({
              type: TYPES.setAttachments,
              payload: [],
            });
            showAlert('Joined Team Successfully!', 'success');
          })
          .catch((err) => {
            showAlert('There was a problem joining your team', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, username, showAlert, endpoint, getStudentDetails]
  );

  const requestUploadVideoLink = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      let link;
      if (username !== 'null') {
        link = await service
          .get('team', '/team/video/add', {
            queryStringParameters: params,
          })
          .then((response) => response.link)
          .catch((err) => {
            showAlert('There was a problem uploading your video', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
      return link;
    },
    [service, dispatch, username, showAlert]
  );

  const getVideos = useCallback(
    async (params, keys) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      if (username !== 'null') {
        const promises = keys.map((key) =>
          service.get('team', '/team/video/get', {
            queryStringParameters: { ...params, key },
          })
        );
        Promise.all(promises)
          .then((results) => {
            dispatch({
              type: TYPES.setVideos,
              payload: results.map((result) => result.files),
            });
          })
          .catch((err) => {
            showAlert('There was a problem getting your files', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, username, showAlert]
  );
  const removeVideo = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      if (username !== 'null') {
        await service
          .del('team', '/team/video/remove', {
            body: params,
          })
          .catch((err) => {
            showAlert('There was a problem removing your file', 'error', err);
          });
      }
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, username, showAlert]
  );
  const changeUser = () => {
    dispatch({
      type: TYPES.setIsLoading,
      payload: true,
    });
    dispatch({
      type: TYPES.setCurrentUser,
      payload: null,
    });
    dispatch({
      type: TYPES.setCurrentApplication,
      payload: null,
    });
    dispatch({
      type: TYPES.setIsLoading,
      payload: false,
    });
    dispatch({
      type: TYPES.setAttachments,
      payload: [],
    });
    dispatch({
      type: TYPES.setStudentDetails,
      payload: null,
    });
  };
  const publicGetSubmissions = useCallback(async () => {
    dispatch({
      type: TYPES.setIsLoading,
      payload: true,
    });
    const result = await service
      .get('public', '/public/submissions/get')
      .then((response) => response)
      .catch((err) => {
        if (!err.response.data || err.response.data.error !== 'Item not found.') {
          showAlert('There was a problem getting all project report details', 'error', err);
        }
      });
    dispatch({
      type: TYPES.setIsLoading,
      payload: false,
    });
    return result;
  }, [service, dispatch, showAlert]);

  const publicGetTeamMembers = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      const result = await service
        .get('public', '/public/members/get', {
          queryStringParameters: params,
        })
        .then((response) => response)
        .catch((err) => {
          if (!err.status) {
            return;
          }
          showAlert('There was a problem getting all team members', 'error', err);
        });
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
      return result;
    },
    [service, dispatch, showAlert]
  );

  const publicGetAttachments = useCallback(
    async (params, keys) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      const promises = keys.map((key) =>
        service.get('public', '/public/attachment/get', {
          queryStringParameters: { ...params, key },
        })
      );
      const files = await Promise.all(promises)
        .then((results) => results.map((result) => result.files))
        .catch((err) => {
          showAlert('There was a problem getting your files', 'error', err);
          return false;
        });
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
      return files;
    },
    [service, dispatch, showAlert]
  );

  const publicGetVideos = useCallback(
    async (params, keys) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      const promises = keys.map((key) =>
        service.get('public', '/public/video/get', {
          queryStringParameters: { ...params, key },
        })
      );
      const files = await Promise.all(promises)
        .then((results) => results.map((result) => result.files))
        .catch((err) => {
          showAlert('There was a problem getting your files', 'error', err);
          return false;
        });
      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
      return files;
    },
    [service, dispatch, showAlert]
  );

  const publicVoteFor = useCallback(
    async (params) => {
      dispatch({
        type: TYPES.setIsLoading,
        payload: true,
      });
      await service
        .put('public', '/public/score/add', {
          body: params,
        })
        .then(() => {
          showAlert('Vote submitted successfully', 'success');
        })
        .catch((err) => {
          showAlert('There was a problem voting for this team', 'error', err);
        });

      dispatch({
        type: TYPES.setIsLoading,
        payload: false,
      });
    },
    [service, dispatch, showAlert]
  );

  return {
    addDetails,
    getDetails,
    updateDetails,
    getStudentDetails,
    addStudentDocuments,
    getSubmission,
    updateSubmission,
    uploadAttachment,
    getAttachments,
    removeAttachment,
    getTeamMembers,
    changeTeam,
    changeUser,
    requestUploadVideoLink,
    getVideos,
    removeVideo,
    publicGetSubmissions,
    publicGetTeamMembers,
    publicGetAttachments,
    publicGetVideos,
    publicVoteFor,
  };
};

export default useActions;
