import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import Sandwich from '../components/SuperheroSandwich';
import { useAuth, useAuthActions } from '../contexts/Authentication';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const { isAuthenticating } = useAuth();
  const { login } = useAuthActions();

  const validateForm = () => email.length > 0 && password.length > 0 && !isAuthenticating;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h4" align="center" gutterBottom>
        Are you a returning superhero?
      </Typography>
      <Sandwich>
        <Grid sm container item spacing={3} direction="column" justify="center" alignItems="center">
          <Grid item xs>
            <TextField
              id="outlined-email-input"
              label="Email"
              type="email"
              autoComplete="current-email"
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Grid>
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
            xs
          >
            <Grid item xs>
              <Button
                variant="contained"
                color="primary"
                disabled={!validateForm}
                onClick={(event) => {
                  event.preventDefault();
                  login(email, password);
                }}
              >
                Login
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                color="secondary"
                disabled={!validateForm}
                onClick={(event) => {
                  event.preventDefault();
                  history.push('/reset-password/forgot');
                }}
              >
                Forgot Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Sandwich>
    </Box>
  );
}
