import ReactAudioPlayer from 'react-audio-player';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function AudioPlayer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography align="center" variant="h5">
        Enrico & Justin on Radju Malta
      </Typography>
      <Box paddingY="10px" />
      <img
        style={{
          borderBottomLeftRadius: '5%',
          borderBottomRightRadius: '5%',
          borderTopLeftRadius: '5%',
          borderTopRightRadius: '5%',
        }}
        src="/radju_malta_1.jpg"
        height={matches ? '200px ' : '400px'}
        alt="EMF Team"
      />
      <Box paddingY="10px" />
      <ReactAudioPlayer src="/RadjuMaltaRecording.mp3" autoPlay controls />
    </Box>
  );
}
