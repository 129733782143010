import axios from 'axios';

export default async function s3VideoUpload(signedUrl, file, progressCallback) {
  const options = {
    onUploadProgress: progressCallback,
    headers: {
      'Content-Type': file.type,
    },
  };
  return axios
    .put(
      signedUrl.replace('https://192.168.65.2:4566', 'http://localhost:8080/static/assets'),
      file,
      options
    )
    .then(() => signedUrl.slice(signedUrl.indexOf('videos/') + 7, signedUrl.indexOf('?')))
    .catch((e) => {
      throw e;
    });
}
