import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Paper, Typography, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function About() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const team = [
    { name: 'Rebekah Attard Trevisan', link: 'RebekahAttardTrevisan.jpg' },
    { name: 'Justin Camilleri', link: 'JustinCamilleri.jpg' },
    { name: 'David Pace', link: 'DavidPace.jpg' },
  ];
  return (
    <Box marginX="20px">
      <Box paddingY="20px" />
      <Typography align="center" variant="h4" gutterBottom>
        About Superheroes in Science Team
      </Typography>
      <Paper elevation={6}>
        <Box padding="10px">
          <Typography align="justify">
            Our aim is to use science & film as an informal education platform to create learning
            experiences and engage audiences. Through our passion for entertainment, we create
            various cultural and entertaining events bringing together people with keen niche
            interests whether for the casual visitor or those well-acquainted with the subject
            matter. In recent years we have hosted Science meets Star Wars (Nov 2017), Superheroes
            in Science (2018-Today), Superheroes & Star Wars in Science (Nov/Dec 2019). These events
            combined science and popular culture in an entertaining way with exhibitions,
            interactive science shows and activities for families and persons of all ages.
          </Typography>
        </Box>
        <Grid container className={classes.root} spacing={2} justify="center">
          <Grid item xs={9}>
            <Grid container justify="center" spacing={2}>
              {team.map((member) => (
                <Grid key={member.name} item>
                  <img
                    src={`team/${member.link}`}
                    alt="team member"
                    height={matches ? '300px' : '450px'}
                  />
                  <Typography align="center" variant="h5" gutterBottom>
                    {member.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
