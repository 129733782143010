/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { NavLink, useLocation } from 'react-router-dom';
import { AppBar, Tabs, Tab, Toolbar, Box, Button, ClickAwayListener } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth, useAuthActions } from '../contexts/Authentication';
import { useUserActions } from '../contexts/User';

const useStyles = makeStyles(() => ({
  tabsContent: {
    height: '100%',
  },
  buttons: {
    color: 'white',
  },
  appBarRoot: {
    background: '#242b5c ',
  },
  appBarRoot2: {
    background: '#323a81',
  },
}));
function Header() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const location = useLocation();
  const { changeUser } = useUserActions();
  const currentPath = location.pathname;
  const { isAuthenticated } = useAuth();
  const { logout } = useAuthActions();
  const [subBar, setSubBar] = useState([]);
  const [showSubBar, setShowSubBar] = useState(false);
  const [selectedLeftTab, setSelectedLeftTab] = useState(0);
  const [selectedSubLeftTab, setSelectedSubLeftTab] = useState(0);
  const [selectedRightTab, setSelectedRightTab] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page

  const openSubBar = (subBarNum) => {
    if (subBarNum === 1) {
      setSubBar(() => [
        <Tab label="About" id="tab-1-0" component={NavLink} to="/science-project" />,
        <Tab label="Rules" id="tab-1-1" component={NavLink} to="/rules" />,
        <Tab label="Prizes" id="tab-1-2" component={NavLink} to="/prizes" />,
        <Tab label="Project Ideas" id="tab-1-3" component={NavLink} to="/ideas" />,
      ]);
      setShowSubBar(true);
      return null;
    }
    return null;
  };

  const setTabHighlight = () => {
    setShowSubBar(false);
    if (currentPath === '/') {
      setSelectedLeftTab(0);
      setSelectedRightTab(false);
      setSelectedSubLeftTab(false);
    } else if (currentPath.includes('science-project')) {
      openSubBar(1);
      setSelectedRightTab(false);
      setSelectedLeftTab(1);
      setSelectedSubLeftTab(0);
    } else if (
      currentPath.includes('application') ||
      currentPath.includes('project') ||
      currentPath.includes('dashboard') ||
      currentPath.includes('signup')
    ) {
      setSelectedRightTab(0);
      setSelectedSubLeftTab(false);
      setSelectedLeftTab(false);
    } else if (currentPath.includes('about-us')) {
      setSelectedRightTab(false);
      setSelectedLeftTab(2);
      setSelectedSubLeftTab(false);
    } else if (currentPath.includes('prizes')) {
      setSelectedRightTab(false);
      setSelectedLeftTab(1);
      setSelectedSubLeftTab(2);
      openSubBar(1);
    } else if (currentPath.includes('rules')) {
      openSubBar(1);
      setSelectedRightTab(false);
      setSelectedLeftTab(1);
      setSelectedSubLeftTab(1);
    } else if (currentPath.includes('faq')) {
      setSelectedRightTab(false);
      setSelectedLeftTab(5);
      setSelectedSubLeftTab(false);
    } else if (currentPath.includes('ideas')) {
      openSubBar(1);
      setSelectedRightTab(false);
      setSelectedLeftTab(1);
      setSelectedSubLeftTab(3);
    } else if (currentPath.includes('login') || currentPath.includes('logout')) {
      setSelectedRightTab(1);
      setSelectedSubLeftTab(false);
      setSelectedLeftTab(false);
    }
    return null;
  };

  useEffect(() => {
    setTabHighlight();
  }, [currentPath]);

  return (
    <Box>
      <AppBar position="static" classes={{ root: classes.appBarRoot }}>
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            flexDirection={matches ? 'column' : 'row'}
            justifyContent={matches ? 'center' : 'space-between'}
          >
            <Menu
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              <MenuItem
                component={NavLink}
                to="/"
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                Home
              </MenuItem>
              <MenuItem
                component={NavLink}
                to="/science-project"
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                About the Project
              </MenuItem>
              <MenuItem
                component={NavLink}
                to="/about-us"
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                About Us
              </MenuItem>
              <MenuItem
                component={NavLink}
                to="/rules"
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                Rules
              </MenuItem>
              <MenuItem
                component={NavLink}
                to="/prizes"
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                Prizes
              </MenuItem>
              <MenuItem component={NavLink} to="/faq">
                FAQ
              </MenuItem>
              <MenuItem component={NavLink} to="/ideas">
                Project Ideas
              </MenuItem>
              {isAuthenticated
                ? [
                    <MenuItem
                      key="0"
                      component={NavLink}
                      to="/dashboard"
                      onClick={() => {
                        setAnchorEl(null);
                      }}
                    >
                      Dashboard
                    </MenuItem>,
                    <MenuItem
                      key="1"
                      onClick={() => {
                        setAnchorEl(null);
                        logout(changeUser);
                      }}
                    >
                      Logout
                    </MenuItem>,
                  ]
                : [
                    <MenuItem
                      key="0"
                      component={NavLink}
                      to="/signup"
                      onClick={() => {
                        setAnchorEl(null);
                      }}
                    >
                      Signup
                    </MenuItem>,

                    <MenuItem
                      key="1"
                      component={NavLink}
                      to="/login"
                      onClick={() => {
                        setAnchorEl(null);
                      }}
                    >
                      Login
                    </MenuItem>,
                  ]}
            </Menu>
            {matches
              ? [
                  <Box key="1" paddingBottom="5px">
                    <Button
                      fullWidth
                      id="main-menu"
                      color="secondary"
                      variant="contained"
                      aria-controls="main-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      Menu
                    </Button>
                  </Box>,
                ]
              : [
                  <Tabs
                    key="0"
                    value={selectedLeftTab}
                    edge="start"
                    orientation={matches ? 'vertical' : 'horizontal'}
                  >
                    <Tab label="Home" id="tab-0" component={NavLink} to="/" />
                    <Tab
                      className={classes.buttons}
                      label="The Project"
                      id="tab-1"
                      component={Button}
                      onClick={() => openSubBar(1)}
                    />
                    <Tab label="About Us" id="tab-2" component={NavLink} to="/about-us" />
                    <Tab label="FAQ" id="tab-3" component={NavLink} to="/faq" />
                  </Tabs>,
                  <Tabs
                    key="1"
                    orientation={matches ? 'vertical' : 'horizontal'}
                    value={selectedRightTab}
                    edge="end"
                    classes={{ flexContainer: classes.tabsContent }}
                  >
                    {isAuthenticated
                      ? [
                          <Tab
                            key="dashboard"
                            label="Dashboard"
                            id="tab-3"
                            component={NavLink}
                            to="/dashboard"
                          />,
                          <Tab
                            key="logout"
                            label="Logout"
                            id="tab-4"
                            onClick={() => logout(changeUser)}
                          />,
                        ]
                      : [
                          <Tab
                            key="signup"
                            label="Signup"
                            id="tab-3"
                            component={NavLink}
                            to="/signup"
                          />,

                          <Tab
                            key="login"
                            label="Login"
                            id="tab-4"
                            component={NavLink}
                            to="/login"
                          />,
                        ]}
                  </Tabs>,
                ]}
          </Box>
        </Toolbar>
      </AppBar>
      {!matches && showSubBar ? (
        <ClickAwayListener
          onClickAway={() => {
            setTabHighlight();
          }}
        >
          <AppBar position="static" classes={{ root: classes.appBarRoot2 }}>
            <Toolbar>
              <Tabs
                key="1"
                value={selectedSubLeftTab}
                edge="start"
                orientation={matches ? 'vertical' : 'horizontal'}
              >
                {subBar.map((i) => i)}
              </Tabs>
            </Toolbar>
          </AppBar>
        </ClickAwayListener>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default Header;
