import { useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { TYPES } from './reducer';

const useActions = (dispatch) => {
  const showAlert = useCallback(
    (alertInfo, alertType, alertStack = '') => {
      if (alertStack) {
        if (alertStack.code !== 'NotAuthorizedException')
          Sentry.captureException(Error(JSON.stringify(alertStack)));
      }
      dispatch({
        type: TYPES.setAlert,
        payload: alertInfo,
      });
      dispatch({
        type: TYPES.setAlertType,
        payload: alertType,
      });
      dispatch({
        type: TYPES.setOpenAlert,
        payload: true,
      });
    },
    [dispatch]
  );
  const hideAlert = useCallback(() => {
    dispatch({
      type: TYPES.setAlert,
      payload: '',
    });
    dispatch({
      type: TYPES.setAlertType,
      payload: '',
    });
    dispatch({
      type: TYPES.setOpenAlert,
      payload: false,
    });
  }, [dispatch]);
  return {
    showAlert,
    hideAlert,
  };
};

export default useActions;
