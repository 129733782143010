import React from 'react';
import { Box, Typography, Grid, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SponsorPrize from '../components/SponsorPrize';

export default function Prizes() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box marginX="20px">
      <Box paddingY="20px" />
      <Typography align="center" variant="h4" gutterBottom>
        Superhero Science Project 2023 Prizes
      </Typography>
      <Paper elevation={6}>
        <Box paddingY="10px" display="flex" flexDirection="column">
          <Typography align="center" variant="h5" color="textSecondary" gutterBottom>
            First place in each of Junior Group 1, Junior Group 2 and Senior Group 1
          </Typography>
          <Grid container spacing={2} justify="center" direction={matches ? 'column' : 'row'}>
            <Grid item xs={matches ? 12 : 6}>
              <SponsorPrize
                logoLink="/Virtu_ferries_logo.svg"
                sponsorLink="http://www.virtuferries.com/"
                sponsorName="Virtu Ferries"
                prizeDescription="One passage with return by Virtu Ferries for the winner/s and
              accompanying adult"
              />
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
              <SponsorPrize
                logoLink="/AgendaBookshoplogo.png"
                sponsorLink="https://ps.agendatextbooks.com/en/"
                sponsorName="Agenda Bookshop"
                prizeDescription="Book Prize for the winner/s by Agenda Bookshop"
              />
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
              <SponsorPrize
                logoLink="/Toylandlogo.png"
                sponsorLink="https://www.facebook.com/ToylandMalta/"
                sponsorName="Toyland Malta"
                prizeDescription="One Gift by Toyland shop for the winner/s"
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
