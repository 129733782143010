import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { Box, Typography, Button, Grid, Chip } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import uuid from 'react-uuid';

export default function CircularMenu({
  setCurrentProject,
  setAllProjects,
  selectedScienceTopics,
  selectedSuperheroTopics,
}) {
  const [description, setDescription] = useState('Loading...');
  const [title, setTitle] = useState('Loading...');
  const [superheroes, setSuperheroes] = useState([]);
  const [topics, setTopics] = useState([]);
  const [data, setData] = useState([]);
  // setData(r.json())

  const [selected, setSelected] = useState(0);
  useEffect(() => {
    if (data.length > 0) {
      setTitle(data[selected].title);
      setDescription(data[selected].description);
      setSuperheroes(data[selected].superheroes);
      setTopics(data[selected].subheaders);
      setAllProjects(data);
    } else {
      fetch('/all_ideas.json')
        .then((r) => r.json())
        .then((r) => {
          setData(r);
        });
    }
  }, [data, selected, setAllProjects]);

  const projectIcons = [
    '029-infinity gauntlet.svg',
    '044-solar.svg',
    "079-joker's gun.svg",
    '031-rorschach.svg',
    '023-silver surfer.svg',
    "071-heman's power sword.svg",
    "094-batman's utility belt.svg",
    "089-ghost rider's hellfire chain.svg",
    '045-black widow.svg',
    '056-brainiac.svg',
    '028-mr fantastic.svg',
    "073-luke's 2nd lightsaber.svg",
    "069-penguin's umbrella.svg",
    '062-hawkeye.svg',
    '066-daredevil glasses.svg',
    '091-gl power ring.svg',
    '050-the spirit.svg',
    '047-poison ivy.svg',
    '005-hawkeye.svg',
    '030-dr manhattan.svg',
    '096-x-men logo.svg',
    '025-xavier.svg',
    '001-batman.svg',
    '018-ironman.svg',
    '042-deadpool.svg',
    '015-catwoman.svg',
    '076-kryptonita.svg',
    '046-iron fist.svg',
    '024-magneto.svg',
  ];

  const isLight = (x) => {
    if (selectedScienceTopics.length === 0 && selectedSuperheroTopics.length === 0) {
      return ' ';
    }
    if (
      selectedScienceTopics.some((r) => x.subheaders.includes(r)) ||
      selectedSuperheroTopics.some((r) => x.superheroes.includes(r))
    ) {
      return ' ';
    }
    return ' light';
  };
  return (
    <div className="icon">
      <Box>
        <Typography align="center" variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          {`${description.substr(0, description.indexOf('.'))} ...`}
        </Typography>
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={1}>
            {superheroes.length > 0 &&
              superheroes.map((superhero) => (
                <Grid item key={uuid()}>
                  <Chip label={superhero.name} color="secondary" variant="outlined" />
                </Grid>
              ))}

            {topics.length > 0 &&
              topics.map((topic) => (
                <Grid item key={uuid()}>
                  <Chip label={topic} color="primary" variant="outlined" />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {data.length > 0 && (
          <Box display="flex" position="relative" zIndex="10">
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={(event) => {
                event.preventDefault();
                setCurrentProject(data[selected]);
              }}
              endIcon={<ArrowForwardIcon />}
            >
              Go To Example Project
            </Button>
          </Box>
        )}
      </Box>

      <ul className="menu">
        {data.length > 0 &&
          data.map((p, i) => (
            <li className="spread" key={uuid()}>
              <Button
                className={(selected === i ? 'unit selected' : 'unit') + isLight(data[i])}
                onClick={() => setSelected(i)}
              >
                <img src={`/svg/${projectIcons[i]}`} alt={`Example project ${i}`} height="50px" />
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
}

CircularMenu.propTypes = {
  setCurrentProject: PropTypes.func.isRequired,
  setAllProjects: PropTypes.func.isRequired,
  selectedScienceTopics: PropTypes.arrayOf(PropTypes.string),
  selectedSuperheroTopics: PropTypes.arrayOf(PropTypes.string),
};

CircularMenu.defaultProps = {
  selectedSuperheroTopics: [],
  selectedScienceTopics: [],
};
