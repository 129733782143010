import React from 'react';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function FakePDFViewer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box width="100%" display="flex" justifyContent="center" alignContent="center">
      <img
        src="/CovidSuperheroesPoster2020.png"
        alt="Covid19 Poster"
        width={matches ? '100%' : '60%'}
      />
    </Box>
  );
}
