import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Collapse,
  CardMedia,
  Fab,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import uuid from 'react-uuid';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 100,
    left: 'auto',
    position: 'fixed',
    zIndex: '100',
  },
  root: {
    maxWidth: 345,
    minHeight: 400,
  },
  media: {
    height: '100px',
  },
  starWars: { border: '1px solid rgb(14, 105, 18)', color: 'rgb(14, 105, 18)' },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  subheader: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function LinearMenu({
  handleOpen,
  setAllProjects,
  selectedScienceTopics,
  selectedSuperheroTopics,
}) {
  const [data, setData] = useState([]);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState([]);

  const handleExpandClick = (i) => {
    setExpanded((oldExpanded) => {
      const newExpanded = oldExpanded;
      newExpanded[i] = !oldExpanded[i];
      return [...newExpanded];
    });
  };

  useEffect(() => {
    if (data.length === 0) {
      fetch('/all_ideas.json')
        .then((r) => r.json())
        .then((r) => {
          const expandedFiller = [];
          for (let index = 0; index < r.length; index += 1) {
            expandedFiller.push(false);
          }
          setExpanded(() => [...expandedFiller]);
          setData(r);
        });
    } else {
      setAllProjects(data);
    }
  }, [data]);

  const isVisible = (x) => {
    if (selectedScienceTopics.length === 0 && selectedSuperheroTopics.length === 0) {
      return true;
    }
    if (
      selectedScienceTopics.some((r) => x.subheaders.includes(r)) ||
      selectedSuperheroTopics.some((r) => x.superheroes.includes(r))
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box margin="10px" height="100%">
      <Fab color="primary" aria-label="filter" className={classes.fab} onClick={handleOpen}>
        <FilterListIcon />
      </Fab>
      <Grid container spacing={3}>
        {data
          .filter((project) => isVisible(project))
          .map((project, index) => (
            <Grid key={uuid()} item xs={12} sm={6} md={4}>
              <Card className={classes.root}>
                <CardHeader
                  classes={{ subheader: classes.subheader }}
                  title={project.title}
                  titleTypographyProps={{ align: 'center' }}
                  subheader={[
                    project.subheaders
                      .map((subheader) => (
                        <Chip key={uuid()} label={subheader} color="primary" variant="outlined" />
                      ))
                      .concat(
                        project.superheroes.map((superhero) => (
                          <Chip
                            key={uuid()}
                            label={superhero.name}
                            classes={project.starWars ? { root: classes.starWars } : undefined}
                            color="secondary"
                            variant="outlined"
                          />
                        ))
                      ),
                  ]}
                />
                {project.imageTitle && (
                  <CardMedia
                    className={classes.media}
                    image={project.imageLink}
                    title={project.imageTitle}
                  />
                )}
                <CardContent>
                  <Typography variant="body2" color="textPrimary">
                    Project:
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {project.description}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Box display="flex" justifyContent="flex-end" alignItems="flex-end" width="100%">
                    <Button
                      classes={{
                        startIcon: expanded[index] ? classes.expandOpen : classes.expand,
                      }}
                      onClick={() => handleExpandClick(index)}
                      aria-expanded={expanded[index]}
                      aria-label="show more"
                      startIcon={<ExpandMoreIcon />}
                    >
                      {expanded[index] ? 'Show less' : 'Show more'}
                    </Button>
                  </Box>
                </CardActions>
                {(project.moreDetails || project.superheroes) && (
                  <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph>
                        About the
                        {project.starWars ? ' star wars phenomenon:' : ' fictional superheroes:'}
                      </Typography>
                      <List id="team-members">
                        {project.superheroes &&
                          project.superheroes.map((superhero) => (
                            <ListItem key={uuid()}>
                              {superhero.avatar && (
                                <ListItemAvatar>
                                  <Avatar />
                                </ListItemAvatar>
                              )}
                              <ListItemText
                                primary={superhero.name}
                                secondary={superhero.description}
                              />
                            </ListItem>
                          ))}
                      </List>
                      {project.moreDetails && <Typography paragraph>More Information:</Typography>}
                      {project.moreDetails}
                    </CardContent>
                  </Collapse>
                )}
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
LinearMenu.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  setAllProjects: PropTypes.func.isRequired,
  selectedScienceTopics: PropTypes.arrayOf(PropTypes.string),
  selectedSuperheroTopics: PropTypes.arrayOf(PropTypes.string),
};

LinearMenu.defaultProps = {
  selectedSuperheroTopics: [],
  selectedScienceTopics: [],
};
