/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Chip, Grid, IconButton } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';
import uuid from 'react-uuid';
import CircularMenu from '../components/CircularMenu';
import ProjectIdea from '../components/ProjectIdea';
import LinearMenu from '../components/LinearMenu';
import Filter from '../components/Filter';

const useStyles = makeStyles(() => ({
  root: {
    flexBasis: '0%',
  },
}));

export default function Ideas() {
  const classes = useStyles();
  const [allProjects, setAllProjects] = useState([]);
  const [allScienceTopics, setAllScienceTopics] = useState([]);
  const [allSuperheroTopics, setAllSuperheroTopics] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [selectedScienceTopics, setSelectedScienceTopics] = useState([]);
  const [selectedSuperheroTopics, setSelectedSuperheroTopics] = useState([]);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (allProjects.length > 0) {
      const scienceTopics = [];
      const superheroTopics = [];
      for (let index = 0; index < allProjects.length; index += 1) {
        for (let index2 = 0; index2 < allProjects[index].subheaders.length; index2 += 1) {
          const element = allProjects[index].subheaders[index2];
          scienceTopics.push(element);
        }
        for (let index2 = 0; index2 < allProjects[index].superheroes.length; index2 += 1) {
          const element = allProjects[index].superheroes[index2].name;
          superheroTopics.push(element);
        }
        setAllScienceTopics([...new Set(scienceTopics)]);
        setAllSuperheroTopics([...new Set(superheroTopics)]);
      }
    }
  }, [allProjects]);

  const toggleScienceTopic = (topic) => {
    const index = selectedScienceTopics.indexOf(topic);
    const tempArr = selectedScienceTopics;
    const tempArr2 = allScienceTopics;
    if (index === -1) {
      tempArr.push(topic);
      tempArr2.splice(tempArr2.indexOf(topic), 1);
    } else {
      tempArr.pop(index, 1);
      tempArr2.push(topic);
    }
    setSelectedScienceTopics(() => [...tempArr]);
    setAllScienceTopics(() => [...tempArr2]);
  };
  const toggleSuperheroTopic = (topic) => {
    const index = selectedSuperheroTopics.indexOf(topic);
    const tempArr = selectedSuperheroTopics;
    const tempArr2 = allSuperheroTopics;
    if (index === -1) {
      tempArr.push(topic);
      tempArr2.splice(tempArr2.indexOf(topic), 1);
    } else {
      tempArr.splice(index, 1);
      tempArr2.push(topic);
    }
    setSelectedSuperheroTopics(() => [...tempArr]);
    setAllSuperheroTopics(() => [...tempArr2]);
  };

  const clearFilters = () => {
    setAllSuperheroTopics(() => [...allSuperheroTopics, ...selectedSuperheroTopics]);
    setAllScienceTopics(() => [...allScienceTopics, ...selectedScienceTopics]);
    setSelectedSuperheroTopics([]);
    setSelectedScienceTopics([]);
  };

  return (
    <Box marginX="20px">
      <Filter
        open={open}
        handleClose={handleClose}
        toggleSuperheroTopic={toggleSuperheroTopic}
        toggleScienceTopic={toggleScienceTopic}
        selectedScienceTopics={selectedScienceTopics}
        selectedSuperheroTopics={selectedSuperheroTopics}
        allScienceTopics={allScienceTopics}
        allSuperheroTopics={allSuperheroTopics}
        clearFilters={clearFilters}
      />
      <Box paddingY="20px" />
      <Typography id="top" align="center" variant="h4" gutterBottom>
        Project Ideas
      </Typography>
      <Box display="flex" width="100%" flexDirection={matches ? 'column' : 'row'}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={matches ? '100%' : '25%'}
        >
          {(matches && selectedScienceTopics.length > 0) || selectedSuperheroTopics.length > 0 ? (
            <Typography align="center" variant="h5" gutterBottom>
              Current Filters
            </Typography>
          ) : (
            <></>
          )}

          {matches ? (
            <></>
          ) : (
            <>
              <Typography align="center" variant="h5" gutterBottom>
                Current Filters
              </Typography>
              <Typography align="center" variant="body1" gutterBottom>
                Click the button below to look for topics and/or superheroes
              </Typography>
              <IconButton
                color="primary"
                aria-label="Go To Filters"
                onClick={(event) => {
                  event.preventDefault();
                  handleOpen();
                }}
              >
                <SearchIcon />
              </IconButton>
            </>
          )}

          <Grid container spacing={1} className={classes.root}>
            <Grid container item xs={12} spacing={1}>
              {selectedScienceTopics.map((topic) => (
                <Grid item key={uuid()}>
                  <Chip
                    label={topic}
                    clickable
                    color="primary"
                    onDelete={() => {
                      toggleScienceTopic(topic);
                    }}
                  />
                </Grid>
              ))}
              {selectedSuperheroTopics.map((topic) => (
                <Grid item key={uuid()}>
                  <Chip
                    label={topic}
                    clickable
                    color="secondary"
                    onDelete={(event) => {
                      event.preventDefault();
                      toggleSuperheroTopic(topic);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          minHeight={matches ? '' : '900px'}
          justifyContent="center"
          width={matches ? '100%' : '75%'}
        >
          {currentProject && (
            <Box display="flex" flexDirection="column" alignItems="center" width="50%">
              <ProjectIdea setCurrentProject={setCurrentProject}>{currentProject}</ProjectIdea>
            </Box>
          )}
          {!currentProject && !matches && (
            <CircularMenu
              setCurrentProject={setCurrentProject}
              setAllProjects={setAllProjects}
              selectedScienceTopics={selectedScienceTopics}
              selectedSuperheroTopics={selectedSuperheroTopics}
            />
          )}
          {!currentProject && matches && (
            <LinearMenu
              handleOpen={handleOpen}
              setAllProjects={setAllProjects}
              selectedScienceTopics={selectedScienceTopics}
              selectedSuperheroTopics={selectedSuperheroTopics}
            />
          )}
        </Box>
      </Box>

      <Paper elevation={6}>
        {!currentProject ? (
          <Box id="examples" paddingY="20px" margin="10px">
            <Typography align="center" variant="h6" gutterBottom>
              A starting place for research and project ideas
            </Typography>
            <Typography paragraph align="justify" variant="body1" gutterBottom>
              This section is aimed for assisting adults who are guiding students in finding ideas
              for creating their project. It is important to encourage them to develop their ideas
              and make their own research once they have established what their project would be.
              Always ensure they look for secure and reliable sources online. The following are all
              facts and details pertaining to the Superhero and Star Wars worlds, to help those who
              are not very familiar with the subject matter.
            </Typography>
            <Box
              display="inline"
              fontWeight="bold"
              paddingX="5px"
              marginY="10px"
              textAlign="justify"
            >
              The above examples are meant to be a source of information only, it is important to
              emphasise that a student does not have to choose any of the following but is free to
              research or select any topic regarding superheroes, super villains or Star Wars, they
              can even create their own!
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Paper>
    </Box>
  );
}

// https://codepen.io/billyysea/pen/AwitH
