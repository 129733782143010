import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function Sandwich(prop) {
  const { children } = prop;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      zeroMinWidth
      container
      spacing={3}
      justify="center"
      alignItems="center"
      direction={matches ? 'column' : 'row'}
    >
      <Grid container item sm justify="center" alignItems="center">
        <img height={matches ? '150px ' : '300px'} alt="Superboy" src="/superboy.png" />
      </Grid>
      {
        // <Grid item xs={4} justify="center" alignItems="center">
        children
        // </Grid>
      }
      <Grid container item sm justify="center" alignItems="center">
        <img height={matches ? '150px ' : '300px'} alt="Supergirl" src="/supergirl.png" />
      </Grid>
    </Grid>
  );
}
