import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Chip,
  Grid,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  FormControl,
} from '@material-ui/core';
import uuid from 'react-uuid';

export default function Filter({
  open,
  handleClose,
  toggleSuperheroTopic,
  toggleScienceTopic,
  selectedScienceTopics,
  selectedSuperheroTopics,
  allScienceTopics,
  allSuperheroTopics,
  clearFilters,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="filter-modal"
      aria-describedby="filter-menu-modal"
    >
      <DialogContent>
        <form noValidate>
          <FormControl>
            <Box display="flex" flexDirection="column">
              <Typography align="center" variant="h5" gutterBottom>
                Current Filters
              </Typography>
              {selectedScienceTopics.length === 0 && selectedSuperheroTopics.length === 0 ? (
                <Typography align="center" variant="h6" gutterBottom>
                  No filters selected yet! Click on the names below to search projects related to
                  those topics and superheroes.
                </Typography>
              ) : (
                <Grid container spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    {selectedScienceTopics.map((topic) => (
                      <Grid item key={uuid()}>
                        <Chip
                          label={topic}
                          clickable
                          color="primary"
                          onDelete={() => {
                            toggleScienceTopic(topic);
                          }}
                        />
                      </Grid>
                    ))}
                    {selectedSuperheroTopics.map((topic) => (
                      <Grid item key={uuid()}>
                        <Chip
                          label={topic}
                          clickable
                          color="secondary"
                          onDelete={(event) => {
                            event.preventDefault();
                            toggleSuperheroTopic(topic);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              <Box paddingY="20px" />
              <Typography align="center" variant="h5" gutterBottom>
                Science Topics
              </Typography>
              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={1}>
                  {allScienceTopics.map((topic) => (
                    <Grid item key={uuid()}>
                      <Chip
                        key={uuid()}
                        label={topic}
                        clickable
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          toggleScienceTopic(topic);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Box paddingY="20px" />
              <Typography align="center" variant="h5" gutterBottom>
                Superheroes & Star Wars
              </Typography>
              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={1}>
                  {allSuperheroTopics.map((topic) => (
                    <Grid
                      item
                      key={uuid()}
                      onClick={() => {
                        toggleSuperheroTopic(topic);
                      }}
                    >
                      <Chip label={topic} clickable color="secondary" variant="outlined" />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Apply Filters & Close
        </Button>
        <Button autoFocus onClick={clearFilters} color="primary">
          Clear All Filters
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  toggleSuperheroTopic: PropTypes.func.isRequired,
  toggleScienceTopic: PropTypes.func.isRequired,
  selectedScienceTopics: PropTypes.arrayOf(PropTypes.string),
  selectedSuperheroTopics: PropTypes.arrayOf(PropTypes.string),
  allScienceTopics: PropTypes.arrayOf(PropTypes.string),
  allSuperheroTopics: PropTypes.arrayOf(PropTypes.string),
  clearFilters: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  selectedSuperheroTopics: [],
  selectedScienceTopics: [],
  allScienceTopics: [],
  allSuperheroTopics: [],
};
