/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useParams } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Link,
  IconButton,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import Alert from '@material-ui/lab/Alert';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import DeleteIcon from '@material-ui/icons/Delete';
import StepLabel from '@material-ui/core/StepLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import StepContent from '@material-ui/core/StepContent';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import { S3Image } from 'aws-amplify-react';
import { useAlertActions } from '../contexts/Alert';
import { useUser, useUserActions } from '../contexts/User';
import { useAuth } from '../contexts/Authentication';
import s3Upload from '../libs/storage-lib';
import s3Remove from '../libs/unstorage-lib';

const useStyles = makeStyles((theme) => ({
  link: {
    paddingLeft: '3px',
    paddingRight: '3px',
    fontWeight: '600',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into its own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Induction() {
  const { showAlert } = useAlertActions();
  const { studentId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [name, setName] = useState(' ');
  const [surname, setSurname] = useState(' ');
  const [phone, setPhone] = useState(' ');
  const [address, setAddress] = useState(' ');
  const [documents, setDocuments] = useState([]);
  const [photographs, setPhotographs] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [imagesUploading, setImagesUploading] = useState(false);
  const [openRejected, setOpenRejected] = useState(false);
  const [tour, setTour] = useState(false);
  const { currentUser, isLoading } = useUser();
  const { getDetails, updateDetails, getStudentDetails, addStudentDocuments } = useUserActions();
  const { cognitoUserObject, identityUserObject } = useAuth();
  const classes = useStyles();
  let email;
  let username;
  if (cognitoUserObject) {
    email = cognitoUserObject.attributes.email;
    username = cognitoUserObject.username;
  }
  const handleCloseRejected = () => {
    setOpenRejected(false);
  };

  useEffect(() => {
    if (!currentUser) {
      getDetails();
    } else if (currentUser) {
      getStudentDetails({
        studentId,
        isParent: cognitoUserObject.attributes['custom:isParent'],
      }).then((response) => {
        setDocuments(response.documents);
        setConfirmed(response.confirmed === 'true' || false);
        setOpenRejected(response.confirmed === 'rejected' || false);
      });
      const selectedStudent = currentUser.filter((student) => student.studentId === studentId)[0];
      setName(selectedStudent.parent_name || ' ');
      setDisclaimer(selectedStudent.disclaimer || false);
      setSurname(selectedStudent.surname || ' ');
      setPhone(selectedStudent.phone || ' ');
      setActiveStep(parseInt(selectedStudent.induction_step, 10) || 0);
      setPhotographs(selectedStudent.photographs === 'true' || false);
      setAddress(selectedStudent.address || ' ');
    }
  }, [getDetails, currentUser, studentId, getStudentDetails, cognitoUserObject.attributes]);

  const steps = ['Some details about you', 'Permissions', 'Upload documents'];

  const renderPictures = () => {
    const allPhotos = documents.map((key) => ({
      key,
      component: (
        <S3Image
          onLoad={() => setImagesUploading(false)}
          level="private"
          imgKey={key.replace(`${identityUserObject}_`, '')}
          theme={{
            photoImg: { maxWidth: '300px' },
          }}
        />
      ),
    }));
    return allPhotos;
  };

  const validateForm = () => {
    switch (activeStep) {
      case 0:
        return (
          name.trim().length > 0 &&
          surname.trim().length > 0 &&
          address.trim().length > 0 &&
          phone.trim().length > 0
        );
      case 1:
        return disclaimer;
      case 2:
        return documents.length >= 1;
      default:
        return false;
    }
  };

  const getDetailsForm = () => (
    <Grid
      id="details-form"
      container
      item
      xs
      spacing={3}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid container item xs spacing={3}>
        <Grid item xs>
          <TextField
            required
            id="outlined-name-input"
            label="Name"
            type="text"
            autoComplete="current-name"
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item xs>
          <TextField
            required
            id="outlined-surname-input"
            label="Surname"
            type="text"
            autoComplete="current-surname"
            variant="outlined"
            value={surname}
            onChange={(event) => setSurname(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container item xs spacing={3}>
        <Grid item xs>
          <TextField
            required
            id="outlined-phone-input"
            label="Phone Number"
            type="number"
            autoComplete="current-phone"
            variant="outlined"
            helperText="This is for emergencies only"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </Grid>
        <Grid item xs>
          <TextField
            required
            id="outlined-address-input"
            label="Address"
            type="text"
            autoComplete="current-address"
            variant="outlined"
            value={address}
            onChange={(event) => setAddress(event.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const getPermissions = () => (
    <FormGroup row>
      <FormControlLabel
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Checkbox
            checked={photographs}
            onChange={() => setPhotographs(!photographs)}
            name="Permission to photograph"
            color="primary"
          />
        }
        label={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Typography>
            (Optional) I give my permission for photos of my student to be taken during events.
          </Typography>
        }
      />
      <FormControlLabel
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Checkbox
            checked={disclaimer}
            onChange={() => setDisclaimer(!disclaimer)}
            name="Accept disclaimer"
            color="primary"
          />
        }
        label={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Typography>
            (Required) I have read the
            <Link target="_blank" rel="noreferrer" href="/disclaimer" className={classes.link}>
              disclaimer
            </Link>
            and accept it. This is to make sure we as the organisers and you as the legal guardian
            are on the same page.
          </Typography>
        }
      />
    </FormGroup>
  );
  async function uploadEach(item, index) {
    const result = await s3Upload(studentId, item, index).then(async (key) => key);
    return result;
  }

  const uploadDocuments = async (uploads) => {
    const promises = uploads.map(uploadEach);
    await Promise.all(promises)
      .then((keys) => {
        addStudentDocuments({
          studentId,
          isParent: cognitoUserObject.attributes['custom:isParent'],
          documents: documents.concat(keys.map((key) => `${identityUserObject}_${key}`)),
        }).then(() => {
          setDocuments(documents.concat(keys));
          showAlert('Documents uploaded successfully!', 'success');
        });
      })
      .catch((e) => {
        showAlert('There was a problem uploading your images', 'error', e);
      });
  };

  const removeItemOnce = (arr, value) => {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const removeDocument = async (item) => {
    await s3Remove(item)
      .then(() => {
        addStudentDocuments({
          studentId,
          isParent: cognitoUserObject.attributes['custom:isParent'],
          documents: removeItemOnce(documents, item),
        }).then(() => {
          setDocuments(removeItemOnce(documents, item));
          showAlert('Document removed successfully!', 'success');
        });
      })
      .catch((e) => {
        showAlert('There was a problem removing this document', 'error', e);
      });
  };

  const getUploadsForm = () => (
    <Box marginBottom="20px" display="flex" flexDirection="column" justifyContent="centre">
      <Box marginBottom="20px">
        <Typography component="div">
          We require an online photo of your national Identity card/passport as well as a photo of
          your student endorsed by yourself as his/her guardian confirming that said photo is a true
          likeness of the participating student. We do this to ensure we have the right permissions
          and these photos will only be used internally to check your identity. Please upload a
          picture of the front and back of your document and a photo of your student. In the case of
          a passport we only require the front. When you have uploaded everything please press
          upload.
        </Typography>
      </Box>
      <GridList className={classes.gridList} cellHeight={200} cols={2.5}>
        {}
        {renderPictures().map((tile, index) => (
          <GridListTile key={tile.key} cols={1}>
            {tile.component}
            <GridListTileBar
              title={`Document ${index + 1}`}
              actionIcon={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <IconButton
                  onClick={() => {
                    removeDocument(tile.key);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
      {documents.length >= 3 ? (
        <Box
          display="inline"
          fontWeight="bold"
          paddingX="5px"
          marginY="10px"
          fontSize="18px"
          textAlign="center"
        >
          You can scroll to the left/right to see more images you have uploaded.
        </Box>
      ) : (
        <></>
      )}
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setOpenUpload(true);
        }}
      >
        Add Images
        <AddIcon />
      </Button>
      <DropzoneDialog
        open={openUpload}
        dialogTitle="Add Documents"
        onSave={(uploads) => {
          uploadDocuments(uploads);
          setOpenUpload(false);
          setImagesUploading(true);
        }}
        showFileNames={false}
        showFileNamesInPreview={false}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        showPreviews
        maxFileSize={5000000}
        onClose={() => {
          setOpenUpload(false);
        }}
      />
    </Box>
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return getDetailsForm();
      case 1:
        return getPermissions();
      case 2:
        return getUploadsForm();
      default:
        return 'Unknown step';
    }
  };

  const handleNext = () => {
    const params = {
      parentId: username,
      studentId,
      parent_name: name,
      surname,
      phone,
      address,
      email,
      disclaimer,
      induction_step: (activeStep + 1).toString(),
      photographs,
    };
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    switch (activeStep) {
      case 0:
        updateDetails(params);
        break;
      case 1:
        updateDetails(params).then(() => {
          showAlert('Permissions updated!', 'success');
        });
        break;
      case 2:
        updateDetails(params).then(() => {
          showAlert('Documents uploaded successfully!', 'success');
        });
        break;
      default:
        showAlert('Error', 'error');
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStartTour = (event) => {
    event.preventDefault();
    setTour(true);
  };

  const handleCallback = (cbProps) => {
    const { status } = cbProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setTour(false);
    }
  };

  const tourSteps = [
    {
      placement: 'center',
      target: 'body',
      content: (
        <Typography>
          We kindly need some information before your student can start filling in their
          application. This is to ensure we have your permission and details. When you&apos;re done
          hit submit and a member of our team will review your and your student&apos;s application.
          If approved, your student is all good to start working on their project!
        </Typography>
      ),
    },
    {
      target: '#details-form',
      content: (
        <Typography>
          Complete each section as instructed. Press &apos;Next&apos; to go to the next section. At
          the end, click &apos;Submit&apos; to send your application!
        </Typography>
      ),
    },
    {
      target: '#step-1',
      content: <Typography>Make sure you read and accept the disclaimer!</Typography>,
    },
    {
      target: '#step-2',
      content: (
        <Typography>
          Here you will be asked to upload pictures of your and your student&apos;s documents. Make
          sure they are clear and follow the instructions shown. When you are done hit
          &apos;Submit&apos; to send the application for a member of our team to check.
        </Typography>
      ),
    },
  ];
  return (
    <Container>
      <Joyride
        callback={handleCallback}
        steps={tourSteps}
        continuous
        run={tour}
        scrollToFirstStep
        showProgress
        showSkipButton
      />
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={handleStartTour}>
          Show me how
        </Button>
      </Box>
      <Backdrop className={classes.backdrop} open={isLoading || imagesUploading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={openRejected} onClose={handleCloseRejected}>
        <DialogTitle id="alert-dialog-title">Previous Application Rejected</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Following your previous application a moderator has decided we may need you to resend
            your details. Perhaps the documents were not clear enough or some details were
            inconsistent/missing. More details on the reason why your application was rejected can
            be found in an email you should have received. We now need you and your child to both
            resubmit your details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejected} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Paper display="flex" elevation={3}>
        <Box
          display="flex"
          flexDirection="column"
          elevation={3}
          justifyContent="center"
          alignItems="center"
          margin="30px"
        >
          <Typography variant="h3">Legal Guardian Checklist</Typography>
          <Typography component="div">
            <Box fontWeight="fontWeightBold">
              Please note that by filling in this application you are hereby granting you student to
              take part in the 2023/2024 Superheroes in Science contest.
            </Box>
            We kindly need some information before your student can start filling in their
            application. This is to ensure we have your permission and details. When you&apos;re
            done hit submit and a member of our team will review your and your student&apos;s
            application. If approved, your student is all good to start working on their project!
          </Typography>
        </Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label} id={`step-${index}`}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
                <div>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      disabled={!validateForm()}
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? 'Submit Application' : 'Next'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length &&
          (!confirmed ? (
            <Alert variant="filled" severity="warning">
              <Typography>
                All steps completed! A member of our team will review your application and check
                your documents. You should receive an email soon with the decision (can take a few
                days).
              </Typography>
            </Alert>
          ) : (
            <Alert variant="filled" severity="success">
              <Typography>You have done this part and you were approved by a moderator!</Typography>
            </Alert>
          ))}
      </Paper>
    </Container>
  );
}
