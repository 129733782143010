import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import Sandwich from '../components/SuperheroSandwich';
import { useAuth, useAuthActions } from '../contexts/Authentication';

export default function Confirm() {
  const { email } = useParams();
  const [code, setCode] = useState('');
  const { isLoading } = useAuth();
  const { confirmSignup } = useAuthActions();
  const validateForm = () => code.length > 0;

  return (
    <Container maxWidth="md">
      <Typography variant="h5" align="center" gutterBottom>
        {`We sent you an email with a code at ${email}.`}
        <br />
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Please insert it here so we know it&apos;s you!
        <br />
        (If you can&apos;t seem to find it try and check the spam folder!)
      </Typography>
      <Sandwich>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            confirmSignup(email, code);
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container item spacing={3} direction="column" justify="center" alignItems="center">
            <Grid item>
              <TextField
                id="outlined-code-input"
                label="Code"
                type="number"
                autoComplete="current-code"
                variant="outlined"
                value={code}
                onChange={(event) => setCode(event.target.value)}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                disabled={!validateForm() && isLoading}
                type="submit"
                variant="contained"
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </form>
      </Sandwich>
    </Container>
  );
}
