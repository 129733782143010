import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
// eslint-disable-next-line no-unused-vars
import Signup from './containers/Signup';
import Prizes from './containers/Prizes';
import Project from './containers/Project';
import FAQ from './containers/FAQ';
import About from './containers/About';
// import Sessions from "./containers/Sessions";
import Rules from './containers/Rules';
import Disclaimer from './containers/Disclaimer';
import ParentInduction from './containers/ParentInduction';
import Dashboard from './containers/Dashboard';
import Confirm from './containers/Confirm';
import Application from './containers/Application';
import PasswordReset from './containers/PasswordReset';
import { withNoAuth, withAuth } from './components/Auth';
import FakePDFViewer from './components/FakePDFViewer';
import AudioPlayer from './components/AudioPlayer';
import Ideas from './containers/Ideas';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />

      <Route path="/signup/:userType?" exact component={Signup} />

      <Route path="/science-project" exact component={Project} />
      {
        // <Route path="/astronomy-sessions" exact component={Sessions} />
      }
      <Route path="/rules" exact component={Rules} />
      <Route path="/faq" exact component={FAQ} />
      <Route path="/about-us" exact component={About} />
      <Route path="/prizes" exact component={Prizes} />
      <Route path="/disclaimer" exact component={Disclaimer} />
      <Route path="/radju-malta-recording" exact component={AudioPlayer} />
      <Route path="/covid-19-poster" exact component={FakePDFViewer} />
      <Route path="/ideas" exact component={Ideas} />
      <Route path="/application/:studentId" exact component={withAuth(ParentInduction)} />
      <Route path="/project" exact component={withAuth(Application)} />
      <Route path="/dashboard" exact component={withAuth(Dashboard)} />
      <Route path="/login" exact component={withNoAuth(Login)} />
      <Route path="/confirm-user/:email" component={withNoAuth(Confirm)} />
      <Route path="/reset-password/:forgot?/:emailSent?" component={withNoAuth(PasswordReset)} />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
