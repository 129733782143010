/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

export default function Rules() {
  return (
    <Box marginX="20px">
      <Box paddingY="20px" />
      <Typography align="center" variant="h4" gutterBottom>
        Superhero Science Project 2023 Rules
      </Typography>
      <Paper elevation={6}>
        <Box paddingY="10px">
          <Typography align="center" variant="h5" color="textSecondary" gutterBottom>
            The following rules apply to students participating in the 2023 Superhero Science
            Project:
          </Typography>
        </Box>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Types of Project Allowed (All types are to be presented online)"
              secondary={[
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography component="span" variant="body1" color="textPrimary">
                          1. An Experiment
                        </Typography>
                      }
                      secondary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography variant="body1" color="textSecondary">
                          A scientific procedure to test or prove a fact or theory or to explain the
                          conditions necessary for something to occur. When conducting your
                          experiment, it is important to state why you are conducting this
                          experiment, describe materials and method used. Graphs or a visual
                          presentation can be used to explain method and results. Remember to give a
                          superhero, supervillain or Star Wars theme to your experiment.
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography component="span" variant="body1" color="textPrimary">
                          2. A Visual Presentation
                        </Typography>
                      }
                      secondary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography variant="body1" color="textSecondary">
                          A project can be shown through photographs, graphics even in comic book
                          style and tables in the form of a chart or any other visual manner which
                          would explain how it works along with written information. Do not forget
                          to include the superhero, supervillain or Star Wars abilities or gadgets
                          you are inspired by.
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography component="span" variant="body1" color="textPrimary">
                          3. A Model
                        </Typography>
                      }
                      secondary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography variant="body1" color="textSecondary">
                          Model projects submitted should not exceed 120 cms x 60 cms in dimensions.
                          Students handy with crafts can create a project that includes a model,
                          static or moving, to explain their work. It is still important to include
                          brief explanations about the aim of your model project and a report. Do
                          not forget to give your model a superhero, supervillain, super vehicle,
                          gadget or Star Wars robot, space vehicle or character theme.
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>,
                <Typography variant="body1" color="textSecondary">
                  Projects which make use of any form of animal testing or experimenting on animals
                  are strictly prohibited. Projects which make use of dangerous chemicals or naked
                  flames must be performed under strict adult supervision. If the origanisers deem
                  the experiment or project to be dangerous or that it might harm the participant/s
                  they may ask the participant/s to change project or be disqualified.
                </Typography>,
              ]}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Important Dates"
              secondary={
                <Typography variant="body1" color="textSecondary">
                  Student participation in the project is free and voluntary. Online applications /
                  submissions will open on January 1, 2024. Official deadline for the submission
                  online is June 2 2024 at 23:59. You also need the online submission form filled by
                  a parent/legal guardian which will be submitted together with your online report.
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Sizes of Team"
              secondary={
                <Typography variant="body1" color="textSecondary">
                  Students can participate individually or as a team of a maximum of three students.
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Competition Groups"
              secondary={
                <>
                  <Typography variant="body1" color="textSecondary">
                    Students will be grouped in one of three separate groups of judging, as follows:
                  </Typography>
                  <Box paddingY="10px" />
                  <Typography variant="body1" color="textSecondary">
                    <strong>Junior Group 1:</strong>
                    Students in Year 3 and 4 during scholastic year 2023/2024
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Junior Group 2:</strong>
                    Students in Year 5 and 6 during scholastic year 2023/2024
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Senior Group 1:</strong>
                    Students in Forms 1 and 2 (Year 7 and 8) during scholastic year 2023/2024
                  </Typography>
                  <Box paddingY="10px" />
                  <Typography variant="body1" color="textSecondary">
                    Therefore, for example, if you are a Form 1 (Year 7) student by the official
                    deadline date of Thursday 30th November 2023 you are part of Senior Group 1 and
                    your project will be judged with projects of other students from Senior Group 1
                    only. Students can team up for this project with a maximum of three (3) students
                    in each team. Participants can also team up within a registered NGO such as Girl
                    Guides, Scouts, Astronomy Clubs, etc.
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Online Competition"
              secondary={
                <Typography variant="body1" color="textSecondary">
                  All projects are to be presented online via this superheroes in science project
                  website. Everything from the application to the parent’s sign-up and the actual
                  physical project has to be presented online utilising photos and videos. The
                  project report must be filled out online and should not exceed 300 words in total
                  for Junior Group 1 and Junior Group 2 and for Senior Group 1 it should not be more
                  than 500 words in total respectively.
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Judging"
              secondary={
                <Typography variant="body1" color="textSecondary">
                  The judging of the entries will be held between June 2, 2024 and June 4, 2024.
                  Judging will be carried out online by the organisers: Superheroes In Science Team
                  and the general public in the following weighting: 25% the general public; 75% by
                  Superheroes In Science Team judges. On June 5 2024, winners will be announced on
                  this website and via a Facebook post while winners themselves will be notified by
                  email or mobile.
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Prizes Eligibility"
              secondary={
                <Typography variant="body1" color="textSecondary">
                  Prizes will be provided for a maximum of a group of three students as per the
                  three cohorts: Junior Group 1 winners - 3 students; Junior Group 2 winners - 3
                  students; Senior Group 1 winners - 3 students. The winner projects can be also an
                  individual effort (one student). If a team wins, the individual students within
                  the team will each receive a prize. In addition a Certificate of Participation
                  will be given to all participants congratulating you on the amazing work you have
                  done!
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Prizes for the 2023 Edition:"
              secondary={[
                <Typography variant="body1" color="textSecondary">
                  One winner from each of Junior Group 1, Junior Group 2 and Senior Group 1:
                </Typography>,
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography component="span" variant="body1" color="textPrimary">
                          One passage with return by Virtu Ferries for the winner/s and accompanying
                          adult.
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography component="span" variant="body1" color="textPrimary">
                          Book Prize for the winner/s by Agenda Bookshop
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography component="span" variant="body1" color="textPrimary">
                          One Gift by Toyland shop for the winner/s
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>,
              ]}
            />
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
}
