/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { Check, Clear } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Sandwich from '../components/SuperheroSandwich';
import { useAuth, useAuthActions } from '../contexts/Authentication';

const useStyles = makeStyles(() => ({
  student: {
    width: '160px',
    height: '300px',
  },
  parent: {
    width: '160px',
    height: '300px',
  },
}));

export default function Signup() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { userType } = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { isAuthenticating } = useAuth();
  const { signup } = useAuthActions();
  const history = useHistory();
  const validatePassword = () =>
    password.length >= 6 && password.match(/[0-9]+/) && password.match(/[A-Z]+/);
  const validateForm = () => email.length > 0 && validatePassword() && confirmPassword === password;
  if (userType === 'parent' || userType === 'student') {
    return (
      <Box display="flex" flexDirection="column">
        {userType === 'parent' ? (
          <Typography variant="h4" align="center" gutterBottom>
            Hi there new superparent!
          </Typography>
        ) : (
          <Typography variant="h4" align="center" gutterBottom>
            Hi there new superhero scientist!
          </Typography>
        )}

        <Sandwich>
          <Grid
            zeroMinWidth
            container
            item
            sm
            spacing={3}
            direction="column"
            justify="center"
            alignItems="center"
          >
            {userType === 'parent' ? (
              <Grid container xs item>
                <Typography align="center">
                  This is where you create a new parent account. Please enter your email correctly
                  as you will be sent an email with an important code!
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                xs
                item
                zeroMinWidth
                direction={matches ? 'column ' : 'row'}
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Typography align="center" display="inline">
                  This is where you create a new account.
                </Typography>
                <Box fontWeight="bold" display="inline" paddingX="5px" textAlign="center">
                  Do not use your parent&apos;s email! Use your own or another one.
                </Box>
                <Typography align="center" display="inline" marginY="5px">
                  Please enter the email correctly as you will be sent an email with an important
                  code!
                </Typography>
              </Grid>
            )}
            <Grid container xs justify="center" spacing={2} alignItems="center" direction="column">
              <Typography>Your password must contain:</Typography>
              <Box marginY="5px" />
              <Chip
                color={password.length >= 6 ? 'primary' : 'secondary'}
                size="small"
                icon={password.length >= 6 ? <Check /> : <Clear />}
                label="At least 6 characters"
              />
              <Box marginY="5px" />
              <Chip
                color={password.match(/[0-9]+/) ? 'primary' : 'secondary'}
                size="small"
                icon={password.match(/[0-9]+/) ? <Check /> : <Clear />}
                label="At least 1 number"
              />
              <Box marginY="5px" />
              <Chip
                color={password.match(/[A-Z]+/) ? 'primary' : 'secondary'}
                size="small"
                icon={password.match(/[A-Z]+/) ? <Check /> : <Clear />}
                label="At least 1 uppercase letter"
              />
              <Box paddingY="10px" />
            </Grid>
            <Grid
              container
              item
              xs
              spacing={3}
              zeroMinWidth
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item xs>
                <TextField
                  id="outlined-email-input"
                  label="Email"
                  type="email"
                  autoComplete="current-email"
                  variant="filled"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="outlined-password-input"
                  label="New Password"
                  error={!validatePassword()}
                  type="password"
                  autoComplete="new-password"
                  variant="filled"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="outlined-new-password-input"
                  label="Retype New Password"
                  type="password"
                  autoComplete="retype-new-password"
                  variant="filled"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!validateForm() || isAuthenticating}
                  onClick={(event) => {
                    event.preventDefault();
                    signup(userType, email, password);
                  }}
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Sandwich>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography variant="h4">Who are you?</Typography>
      <Box marginY="20px" />
      <Grid container spacing={1} direction="row" justify="center" alignItems="center">
        <Grid container item xs justify="center" alignItems="center" direction="column">
          <Box display="flex" justifyContent="center">
            <img alt="Student" className={classes.student} src="/robin.png" />
          </Box>
          <Box marginY="10px" />
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              history.push('signup/student');
            }}
          >
            I&apos;m a student
          </Button>
        </Grid>
        <Grid container item xs justify="center" alignItems="center" direction="column">
          <Box display="flex" justifyContent="center">
            <img alt="Parent" src="/batman.png" className={classes.parent} />
          </Box>
          <Box marginY="10px" />
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              history.push('signup/parent');
            }}
          >
            I&apos;m a parent
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
