import React from 'react';
import { Paper, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  textContent: {
    padding: '20px',
  },
}));

export default function Disclaimer() {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" margin="20px">
      <Typography variant="h4" align="center" gutterBottom>
        Disclaimers for students, parents and legal guardians
      </Typography>
      <Paper>
        <Box paddingY="10px" />
        <Typography variant="h4" align="center" gutterBottom>
          Safety First
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Always ask an adult when handling sharp tools, any form of electrical equipment or
          dangerous chemicals.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Protect your eyes: wear safety glasses and gloves when handling chemicals.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Wear a safety mask if handling chemicals that are harmful if inhaled.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Do not allow chemicals to come in direct contact with your skin or eyes.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Protect your clothing when handing acidic chemicals such as vinegar or paints.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Do not place food or drink close to the area where you are working
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Always wash hands well and clean equipment and surfaces after you finish your project.
        </Typography>
        <Box paddingY="10px" />
        <Typography variant="h4" align="center" gutterBottom>
          Copyrights and legal issues
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          All participants must comply with European and national laws including but not limited to
          copyright laws, legislation prohibiting the publication of any defamatory, discriminatory
          or other illegal content or any other similar laws.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          The Superheroes in Science Project (&quot;the Competition&quot;) is organized by Euro
          Media Forum (&quot;Organizer&quot;,&quot;EMF&quot;). By registering and uploading content,
          contestants declare that the content submitted is their original work and creation. The
          Organizer does not assume any responsibility for disputes between persons claiming
          copyrights of content. By registering and uploading content, each contestant declares that
          the content does not infringe any third party rights and that they have obtained all
          necessary rights and licenses from third parties for the use of any materials. In uploaded
          videos, contestants may not use any music that is not in the public domain or for which
          they have not acquired the necessary rights and licenses. The contestants will be fully
          responsible to the Organizer for any breach of the conditions contained in this disclaimer
          and, in particular, will hold the Organizer harmless from any actions brought by third
          parties.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          The Organizer reserves the right to exclude proposals submitted for the Competition if it
          comes to their knowledge that there are copyright infringements or the content contains
          defamatory, discriminatory or other illegal material or does not comply with national
          legislation. By registering and uploading content, the contestants agree that the
          Organizer shall have the right to make the content freely available for educational
          purposes on the web, CD-ROM or in any other media format for a period of 3 years starting
          from the date of the close of entries for the Competition. By submitting their content,
          contestants declare their agreement that the Organizer may freely use any presentations,
          videos or images in all media formats in its promotional activities of the awards. The
          Organiser shall have complete discretion as to how they want to claim and exercise these
          rights.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          By registering and uploading content the contestants declare that all identifiable
          individuals in their entry consented to the use of their image/performance, the submission
          of the content in this competition, and the use of the content by the Organizer for
          educational and promotional purposes in all media formats, including the web, for a period
          of 3 years from the date of the close of entries for the Competition. Contestants declare
          that the individuals who have provided content for their submission will sign all
          necessary documents granting the Organizer, if required by it, the rights free of charge
          to use the content as indicated above.
        </Typography>
        <Typography id="privacy-policy" variant="h4" align="center" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          The following statement explains our policy regarding the personal information we collect
          about you for the purposes of the Superhero Science Project (&quot;Competition&quot;).
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Overview
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          The Competition Platform is dedicated to respecting the privacy of its users. Our
          intention is to provide a secure and safe environment for all users. The following
          statement outlines the policies by which the Platform collects, manages, and uses the data
          provided to us by users, and how users of the portal may exercise their rights. Use of the
          Platform indicates you have read these policies and agree to abide by them. Contact
          information for Euro Media Forum can be found below.
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Information Collection
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          To serve its users in the best way possible, the Platform requires users to submit the
          following information as a part of the application for the Competition: Applicant’s data
          (Adults): full name, surname, email address, phone number, a copy of ID card or passport.
          Applicant’s data (Minors): full name, age, e-mail address, school or organization (if
          applicable), a copy of ID card or passport.. In addition, information such as user IP
          address, internet service provider (ISP), web browser, operating system, approximate times
          of use, referring site, email address and any similar data exchanged between a user’s
          computer and the servers of the Platform will be collected. These will not be linked to
          specific user accounts except as indicated below. Finally, aggregate statistics will be
          collected regularly, including but not limited to the number of users and page statistics
          during a specific period.
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Use of collected information
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Personal information provided by the user or collected during usage of the Platform will
          only be used to: Implement the Competition including judging the entries, publishing the
          names of the winners in the media and awarding the prizes. Enable and improve the user
          experience within this project. Send print materials and other items by post on request by
          members using address details provided by them. Access to this information is strictly
          limited to Euro Media Forum, as well as individual experts and judges working for the
          Organiser. The transfer of specific data to other third parties can be permitted under the
          specific authorisation of Euro Media Forum acting as the Data Controller; such
          authorisation will only be granted by the Data Controller to third parties with a
          legitimate interest in the Competition, including the European Commission. Personal data
          will only be transferred in accordance with applicable regulations. Euro Media Forum will
          not divulge your personal data for direct marketing purposes to third parties. Euro Media
          Forum will have access to provided account information and can modify and/or delete
          information as needed to maintain the integrity of the user database or to ensure the
          proper operation of the Platform.
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Length of data storage
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Personal data provided by the users of the site or collected during their usage of the
          site will not be stored by Euro Media Forum in a form allowing the identification of the
          users for longer than three years after the date of the close of entries for Competition.
          In addition, registered users may contact Euro Media Forum as indicated below to terminate
          their accounts, which will result in the deletion of the personal data that they have
          provided during registration.
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Site security
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          The Platform has implemented and continues to maintain appropriate technical and
          management measures to keep your personal information secure and safe from loss, damage,
          corruption, or deletion.
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Changes within this privacy policy
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Any and all changes to this privacy policy will be posted here, and will take effect upon
          their publication. If you have any questions or concerns about this privacy policy, at any
          time, please contact: superheroscienceinfo@gmail.com
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Contacting Euro Media Forum
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Euro Media Forum is based in Malta. For any questions related to the Platform, including
          your rights to access, correct and delete your personal information under applicable data
          protection law, you can contact: superheroscienceinfo@gmail.com
        </Typography>
        <Typography variant="h4" align="center" gutterBottom>
          Parents and legal guardians permissions
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          Euro Media Forum (EMF) does not hold responsibility for the loss of or damage to any
          personal possessions or the projects or equipment exhibited during any event organised by
          EMF. Students should be monitored at all times and are the sole responsibility of the
          adults whose accounts are connected to in the application phase.
        </Typography>
        <Typography align="justify" className={classes.textContent}>
          The terms &ldquo;I&ldquo;, &ldquo;me&ldquo; or &ldquo;my&ldquo; refer to the participating
          student. I hereby authorise Euro Media Forum, its employees and collaborators to interview
          me or use photographs and MP4 video of me and my project in connection with the
          Superheroes in Science Project 2024 online edition where all the information will be
          inserted in the Superheroes in Science Project 2024 website: https://superheroscience.info
          . I authorise EMF to use and permit other persons to use such as newspapers and other
          media to use my name, picture, portrait likeness or any or all of them., for any purpose
          and in any manner EMF may deem appropriate. I agree that my photographs and photographs of
          my science project may be used for purposes including, but not limited to, dissemination
          to members of the public for educational, research, scientific and public relations. I
          also agree that my photographs, recordings, and all reproductions thereof, including but
          not limited to photographic negatives and video connected therewith, are and shall remain
          the property of EMF. I hereby allow Euro Media Forum, its successors and to anyone acting
          under the authority or permission of EMF the right to make or reproduce my photograph in
          any form or manner, to copyright any of the originals or copies where appropriate and to
          use them for any lawful broadcast or non-media broadcast purposes. I have entered into
          this agreement in order to assist EMF in its mission of teaching, research, and public
          service and I hereby waive any right to compensation for these uses by reason of the
          foregoing authorisations. I hereby release and forever discharge EMF, its representatives,
          employees and collaborators, either in their individual capacities, from all claims and
          demands whatsoever that I or any other persons acting on my behalf or on the behalf of my
          estate have or may have against Euro Media Forum or any or all of the above mentioned
          persons or their successors by reason of the above-mentioned allow of permission. In this
          agreement, the term &ldquo;photograph&ldquo; shall mean motion picture or still photograph
          in any format, as well as video, MP4 and any other mechanical or electronic means or
          recording and reproducing images and sound.
        </Typography>
      </Paper>
    </Box>
  );
}
