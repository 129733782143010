import React from 'react';
import { Box, Paper, Typography, Grid, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import PDFViewer from "pdf-viewer-reactjs";

export default function Project() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box marginX="20px">
      <Box width="100%" display="flex" justifyContent="center" alignContent="center">
        <img
          src="/SuperheroesinScienceProjectlogo.png"
          alt="Superhero Science Project Logo"
          height={matches ? '150px' : '250px'}
        />
      </Box>
      <Paper elevation={6}>
        <Box display="flex" flexDirection="column">
          <Grid container spacing={2} justify="center">
            <Grid container item sm>
              <Box padding="10px">
                <Typography align="justify">
                  The Superheroes in Science Project is an initiative to involve schools in the
                  creation of science based projects on the real science behind superheroes.
                  Students are given the possibility to create science projects pertaining to the
                  real science behind superheroes, Star Wars and space travel and how they can apply
                  scientific principles being taught in their syllabus as well as discoveries and
                  innovations.
                </Typography>
                <Box paddingY="10px" />
                <Typography align="justify">
                  The aim of this project is to stimulate the students&apos; sense of curiosity and
                  fun, to discover and research science through the world of Superheroes, Star Wars
                  and space travel. Below you can find the 2023 Edition of the Superheroes in
                  Science Project Booklet. It is aimed at students wishing to participate in this
                  year&apos;s project and contains lots of useful information and ideas. This
                  year&apos;s edition has now ended, but you can always check the information to
                  participate next year!
                </Typography>
                {/* <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  marginTop="20px"
                >
                  <Button
                    component={NavLink}
                    to="#submissions"
                    variant="contained"
                    color="secondary"
                  >
                    Check this year&apos;s submissions!
                  </Button>
                </Box> */}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  marginTop="20px"
                >
                  <Button
                    href="/EMF_Brochure_2020.pdf"
                    target="_blank"
                    rel="noreferrer"
                    variant="contained"
                    color="primary"
                  >
                    Download the 2023 Brochure Here!
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
