import React from 'react';
import { Box, CardMedia, Card } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import IconButton from '@material-ui/core/IconButton';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Carousel from 'react-material-ui-carousel';
import uuid from 'react-uuid';

const useStyles = makeStyles(() => ({
  bannerContainer: {
    background: '#e6b50a',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  logos: {
    color: '#242b5c',
  },
  link: {
    paddingLeft: '3px',
    paddingRight: '3px',
    fontWeight: '600',
  },
  Banner: {
    background: '#e6b50a',
    height: '250px',
    position: 'relative',
    boxShadow: 'None',
  },
  Media: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    backgroundSize: 'contain',
  },
}));

export default function EventsBanner() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box className={classes.bannerContainer} height={matches ? '160px' : '300px'}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        width={matches ? '100%' : '25%'}
      >
        <Box
          display="flex"
          justifyContent="center"
          marginLeft={matches ? '0px' : '20px'}
          marginTop="20px"
        >
          <img src="/title.png" alt="Superhero Science Logo" height={matches ? '110px' : '110px'} />
        </Box>
        <Box display="flex" justifyContent="center">
          <IconButton
            className={classes.logos}
            aria-label="facebook-redirect"
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/superheroesinscience/"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            className={classes.logos}
            aria-label="instagram-redirect"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/shscience/"
          >
            <InstagramIcon />
          </IconButton>
        </Box>
      </Box>
      {matches ? (
        <></>
      ) : (
        <Box width="75%" paddingLeft="20px">
          <Carousel navButtonsAlwaysVisible>
            {[0].map((item) => (
              <Card key={uuid()} className={classes.Banner}>
                <CardMedia
                  className={classes.Media}
                  image="/SuperheroesinScienceMasksFBBanner.png"
                  title={`event ${item}`}
                />
              </Card>
            ))}
          </Carousel>
        </Box>
      )}
    </Box>
  );
}
