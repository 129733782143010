import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArticleSummary from '../components/ArticleSummary';

export default function Home() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      <Grid container spacing={0} justify="center" direction={matches ? 'column' : 'row'}>
        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            timeStamp="01/01/2024"
            title="Superhero Science Project 2024 Now Open!"
            summary="The Superheroes in Science Project is an initiative to involve
            schools in the creation of science based projects on the real
            science behind superheroes. Superheroes in Science is proud to
            present its sixth edition!
            Amazing prizes to be won - signup now! Deadline is June 2, 2024."
            image
            imageSrc="/supergirl.png"
            cta
            ctaText="Learn More & Apply"
            ctaLink="/science-project"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            timeStamp="18/09/2023"
            title="Superhero Science Project 2023 Now Open!"
            summary="The Superheroes in Science Project is an initiative to involve
            schools in the creation of science based projects on the real
            science behind superheroes. Superheroes in Science is proud to
            present its sixth edition!
            Amazing prizes to be won - signup now! Deadline is the 30th of November 2023."
            image
            imageSrc="/superboy.png"
            cta
            ctaText="Learn More & Apply"
            ctaLink="/science-project"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            timeStamp="17/09/2022"
            title="Superhero Science Project 2022 Now Open!"
            summary="The Superheroes in Science Project is an initiative to involve
            schools in the creation of science based projects on the real
            science behind superheroes. Superheroes in Science is proud to
            present its fifth edition which now includes the new Star Wars
            Science section.
            Other amazing prizes to be won - signup now! Deadline is the 4th of November 2022."
            image
            imageSrc="/superboy.png"
            cta
            ctaText="Learn More & Apply"
            ctaLink="/science-project"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            timeStamp="30/08/2021"
            title="Superhero Science Project 2021 Deadline Extended!"
            summary="Deadline for submitting your projects has been extended to the 5th of September 2021! Make sure to have your projects ready by then!"
            image
            imageSrc="/spidey_vs_stein.png"
            cta
            ctaText="Learn More & Apply"
            ctaLink="/science-project"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            timeStamp="01/06/2021"
            title="Superhero Science Project 2021 Now Open!"
            summary="The Superheroes in Science Project is an initiative to involve
            schools in the creation of science based projects on the real
            science behind superheroes. Superheroes in Science is proud to
            present its third edition which now includes the new Star Wars
            Science section. Other amazing prizes to be won - signup now!"
            image
            imageSrc="/spidey_vs_stein.png"
            cta
            ctaText="Learn More & Apply"
            ctaLink="/science-project"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            image
            imageSrc="/supergirl.png"
            timeStamp="16/09/2020"
            title="2020 winners announced!"
            summary={
              "The project competition for this year has concluded. We have had amazing projects from all age groups \
              and we wish to congratulate every single student. Go check out this year's winners!"
            }
            cta
            ctaText="Checkout who won!"
            ctaLink="/science-project#submissions"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            image
            imageSrc="/superboy.png"
            timeStamp="01/09/2020"
            title="2020 voting is open!"
            summary={
              'Project submission has officially ended! Go vote for your favourite project. \
              50% of the vote will be from the public while 50% from the judges. This way \
              on the 16th of September 2020 we will announce the winners.'
            }
            cta
            ctaText="Go Vote"
            ctaLink="/science-project#submissions"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            image
            imageSrc="/radju_malta_0.jpg"
            timeStamp="27/07/2020"
            title="We visited Radju Malta!"
            summary={
              "On July 27th 2020 we were invited on the radio programme Familja Waħda on Radju Malta 93.7 FM. We discussed the Superheroes in Science project and gave some information about this year's rules. We also discussed some ideas for projects and finally strongly encouraged everyone listening to participate!"
            }
            cta
            ctaText="Listen to the recording"
            ctaLink="/radju-malta-recording"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            image
            imageSrc="/SuperheroesinScienceMasksFBBanner.png"
            timeStamp="17/07/2020"
            title="Preventing Covid-19"
            summary={[
              'Stay vigilant',
              'Stay safe',
              'You can be a superhero and protect people',
              'Science is real',
              'Kindness is everything',
              'Wear the mask!',
            ]}
            cta
            ctaText="See what you can do"
            ctaLink="/covid-19-poster"
          />
        </Grid>

        <Grid container item xs={matches ? 12 : 6}>
          <ArticleSummary
            timeStamp="01/07/2020"
            title="Superhero Science Project 2020 Now Open!"
            summary="The Superheroes in Science Project is an initiative to involve
            schools in the creation of science based projects on the real
            science behind superheroes. Superheroes in Science is proud to
            present its third edition which now includes the new Star Wars
            Science section. Other amazing prizes to be won - signup now!"
            image
            imageSrc="/spidey_vs_stein.png"
            cta
            ctaText="Learn More & Apply"
            ctaLink="/science-project"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
