import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  link: {
    paddingLeft: '3px',
    paddingRight: '3px',
    fontWeight: '600',
  },
}));

export default function SponsorPrize(props) {
  const { logoLink, sponsorLink, sponsorName, prizeDescription } = props;
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      marginBottom="20px"
    >
      <img src={logoLink} height="70px" alt={`${sponsorName} Logo`} />
      <Typography align="center" variant="h6" display="inline">
        {prizeDescription}
      </Typography>
      <Typography align="center" variant="body2">
        <Link
          color="inherit"
          underline="always"
          target="_blank"
          rel="noreferrer"
          href={sponsorLink}
          className={classes.link}
        >
          Go to Sponsor Website
        </Link>
      </Typography>
    </Box>
  );
}
SponsorPrize.propTypes = {
  logoLink: PropTypes.string.isRequired,
  sponsorLink: PropTypes.string.isRequired,
  sponsorName: PropTypes.string.isRequired,
  prizeDescription: PropTypes.string.isRequired,
};
