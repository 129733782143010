/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  Checkbox,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  FormHelperText,
  Link,
  CircularProgress,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Step from '@material-ui/core/Step';
import Alert from '@material-ui/lab/Alert';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useAlertActions } from '../contexts/Alert';
import { useUser, useUserActions } from '../contexts/User';
import { useAuth } from '../contexts/Authentication';

const useStyles = makeStyles((theme) => ({
  link: {
    paddingLeft: '3px',
    paddingRight: '3px',
    fontWeight: '600',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Induction() {
  const { showAlert } = useAlertActions();
  const [activeStep, setActiveStep] = React.useState(0);
  const [name, setName] = useState(' ');
  const [surname, setSurname] = useState(' ');
  const [age, setAge] = useState(' ');
  const [school, setSchool] = useState(' ');
  const [rulesAccepted, setRulesAccepted] = useState(false);
  const [parentEmail, setParentEmail] = useState(' ');
  const { currentUser, isLoading } = useUser();
  const [confirmed, setConfirmed] = useState(false);
  const [openRejected, setOpenRejected] = useState(false);
  const [tour, setTour] = useState(false);
  const { addDetails, getDetails, updateDetails } = useUserActions();
  const { cognitoUserObject } = useAuth();
  const classes = useStyles();

  let email;
  let username;
  if (cognitoUserObject) {
    email = cognitoUserObject.attributes.email;
    username = cognitoUserObject.username;
  }
  const handleCloseRejected = () => {
    setOpenRejected(false);
  };
  useEffect(() => {
    if (!currentUser) {
      getDetails();
    } else {
      setName(currentUser.student_name || ' ');
      setSurname(currentUser.surname || ' ');
      setSchool(currentUser.school || ' ');
      setOpenRejected(currentUser.confirmed === 'rejected' || false);
      setActiveStep(parseInt(currentUser.induction_step, 10) || 0);
      setRulesAccepted(currentUser.accepted_rules === 'true');
      setAge(currentUser.year_group || 1);
      setConfirmed(currentUser.confirmed === 'true' || false);
    }
  }, [currentUser, getDetails]);

  const steps = ['Tell us about yourself', 'Accept the rules', 'Get permission from an adult'];

  const validateForm = () => {
    switch (activeStep) {
      case 0:
        return name.trim().length > 0 && surname.trim().length > 0 && age > 0 && age < 4;
      case 1:
        return rulesAccepted;
      case 2:
        return parentEmail.length > 0 && parentEmail.includes('@');
      default:
        return false;
    }
  };

  const getDetailsForm = () => (
    <Grid
      id="details-form"
      xs
      container
      item
      spacing={3}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid container item xs spacing={3}>
        <Grid item xs>
          <TextField
            required
            id="outlined-name-input"
            label="Name"
            type="text"
            autoComplete="current-name"
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item xs>
          <TextField
            required
            id="outlined-surname-input"
            label="Surname"
            type="text"
            autoComplete="current-surname"
            variant="outlined"
            value={surname}
            onChange={(event) => setSurname(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container item xs spacing={3}>
        <Grid item xs>
          <FormControl required>
            <InputLabel>Year Group</InputLabel>
            <Select
              autoWidth
              id="outlined-age-input"
              value={age}
              onChange={(event) => setAge(event.target.value)}
            >
              <MenuItem value={1}>Junior 1 (Grade 3 & 4)</MenuItem>
              <MenuItem value={2}>Junior 2 (Grade 5 & 6)</MenuItem>
              <MenuItem value={3}>Senior 1 (Grade 7 & 8)</MenuItem>
            </Select>
            <FormHelperText>
              Choose according to the school year you are in 2023/2024
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs>
          <TextField
            id="outlined-school-input"
            label="School/NGO"
            type="text"
            autoComplete="current-school"
            variant="outlined"
            value={school}
            onChange={(event) => setSchool(event.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const getRules = () => (
    <FormGroup row id="rules-form-group">
      <FormControlLabel
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Checkbox
            checked={rulesAccepted}
            onChange={() => setRulesAccepted(!rulesAccepted)}
            name="I accept the rules"
            color="primary"
          />
        }
        label={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Typography>
            I have read the
            <Link target="_blank" rel="noreferrer" href="/rules" className={classes.link}>
              rules
            </Link>
            and agree to follow them. This is to prevent harm to you or other people and to make
            sure we have a fair competition. When you are done reading the rules, please tick the
            box.
          </Typography>
        }
      />
    </FormGroup>
  );

  const getParentsEmailForm = () => (
    <Grid xs container spacing={3} direction="column" justify="center" alignItems="center">
      <Grid item xs>
        <Typography align="center">
          Please type your legal guardian&apos;s email. We will send an email asking for permission
          so tell them to check this!
        </Typography>
      </Grid>
      <Grid item xs>
        <TextField
          id="outlined-email-input"
          label="Email"
          type="email"
          autoComplete="current-email"
          variant="outlined"
          value={parentEmail}
          onChange={(event) => setParentEmail(event.target.value)}
        />
      </Grid>
    </Grid>
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return getDetailsForm();
      case 1:
        return getRules();
      case 2:
        return getParentsEmailForm();
      default:
        return 'Unknown step';
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const params = {
      studentId: username,
      student_name: name,
      surname,
      year_group: age,
      school,
      email,
      induction_step: (activeStep + 1).toString(),
      accepted_rules: rulesAccepted.toString(),
      parent_email: parentEmail,
    };
    switch (activeStep) {
      case 0:
        addDetails(params);
        break;
      case 1:
        updateDetails(params).then(() => {
          showAlert('Thank you for accepting our rules!', 'success');
        });
        break;
      case 2:
        updateDetails(params);
        break;
      default:
        showAlert('Error', 'error');
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStartTour = (event) => {
    event.preventDefault();
    setTour(true);
  };

  const handleCallback = (cbProps) => {
    const { status } = cbProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setTour(false);
    }
  };

  const tourSteps = [
    {
      placement: 'center',
      target: 'body',
      content: (
        <Typography>
          Here we need some of your details before you can start telling us about your amazing
          project! Get an adult if possible so they can help you to put in the details.
        </Typography>
      ),
    },
    {
      target: '#details-form',
      content: (
        <Typography>
          Complete each section as instructed. Press &apos;Next&apos; to go to the next section. At
          the end, click &apos;Submit&apos; to send your application!
        </Typography>
      ),
    },
    {
      target: '#outlined-age-input',
      content: (
        <Typography>
          Make sure to select the right group. This is according to the school grade you were in
          2023/2024. If you are not sure, check the project booklet!
        </Typography>
      ),
    },
    {
      target: '#outlined-school-input',
      content: (
        <Typography>
          This can be Skolsajf or your own school but remember: You can only send one application,
          you cannot have one for Skolasajf and another for your school!
        </Typography>
      ),
    },
    {
      target: '#step-1',
      content: <Typography>Make sure you read and accept the rules!</Typography>,
    },
    {
      target: '#step-2',
      content: (
        <Typography>
          Make sure you carefully and correctly type in your parent&apos;s email address.
        </Typography>
      ),
    },
  ];
  return (
    <Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Joyride
        callback={handleCallback}
        steps={tourSteps}
        continuous
        run={tour}
        scrollToFirstStep
        showProgress
        showSkipButton
      />
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={handleStartTour}>
          Show me how
        </Button>
      </Box>
      <Dialog open={openRejected} onClose={handleCloseRejected}>
        <DialogTitle id="alert-dialog-title">Previous Application Rejected</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hi there! After you and your parent submitted your details a member of our team has
            looked at them and we need you to do it again. Something may have been missing or
            inconsistent. Tell your parent to look at their account so they can also resubmit their
            details and you can start creating your project soon!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejected} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Paper flex elevation={3}>
        <Box
          display="flex"
          flexDirection="column"
          elevation={3}
          justifyContent="center"
          alignItems="center"
          margin="30px"
        >
          <Typography variant="h3">Superhero Scientist Checklist</Typography>
          <Typography>
            Welcome, new scientist! Before you can start telling us about your amazing superheroes
            in science project, we need some details about you so we can start filling in your
            application. Where you see * means that detail is required. If you need, ask an adult to
            help you fill this in.
          </Typography>
        </Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step id={`step-${index}`} key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
                <div>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      disabled={!validateForm()}
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length &&
          (!confirmed ? (
            <Alert variant="filled" severity="warning">
              <Typography>
                All steps completed! Now go tell your legal guardian to check their email so we can
                start processing your application.
              </Typography>
            </Alert>
          ) : (
            <Alert variant="filled" severity="success">
              <Typography>You have done this part and you were approved by a moderator!</Typography>
            </Alert>
          ))}
      </Paper>
    </Container>
  );
}
