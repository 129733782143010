import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import IconButton from '@material-ui/core/IconButton';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
  footer: {
    background: '#242b5c',
    color: 'white',
    height: '100%',
    display: 'flex',
    paddingTop: '20px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
  },
  link: {
    paddingLeft: '3px',
    paddingRight: '3px',
    fontWeight: '600',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box className={classes.footer}>
      <Typography align="center">This website was created and is maintained by</Typography>
      <Typography align="center">
        <Link
          color="inherit"
          underline="always"
          target="_blank"
          rel="noreferrer"
          href="https://enricozammitlonardelli.com"
          className={classes.link}
        >
          Enrico Zammit Lonardelli
        </Link>
      </Typography>
      <Box paddingY="5px" />

      <Typography align="center" display="inline">
        Click here to find us on
        <IconButton
          aria-label="facebook-redirect"
          color="inherit"
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/superheroesinscience/"
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          aria-label="instagram-redirect"
          color="inherit"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/shscience/"
        >
          <InstagramIcon />
        </IconButton>
      </Typography>
      <Box paddingY="5px" />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography align="center">
          <Link color="inherit" underline="always" href="/about-us" className={classes.link}>
            About Us
          </Link>
        </Typography>
        <Typography align="center">
          <Link
            color="inherit"
            underline="always"
            href="/disclaimer#privacy-policy"
            className={classes.link}
          >
            Privacy Policy
          </Link>
        </Typography>
        <Typography align="center">
          <Link
            color="inherit"
            underline="always"
            href="mailto:superheroscienceinfo@gmail.com"
            className={classes.link}
          >
            Contact Us
          </Link>
        </Typography>
      </Box>
      <Box paddingY="5px" />
      <Typography align="center">This project is supported by</Typography>
      <Box
        height="100%"
        display="flex"
        flexDirection={matches ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
        bgcolor="white"
        padding="10px"
      >
        <img src="/Toylandlogo.png" height="70px" alt="Toyland Logo" />
        <Box paddingX="5px" />
        <img
          // src="/VirtuFerrieslogo.png"
          src="/Virtu_ferries_logo.svg"
          height="70px"
          alt="Virtu Ferries Logo"
        />
        <Box paddingX="5px" />
        <img src="/AgendaBookshoplogo.png" height="70px" alt="Agenda Bookshop Logo" />
        <Box paddingX="5px" />
      </Box>
    </Box>
  );
}
