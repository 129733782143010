import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box } from '@material-ui/core';

export default function NotFound() {
  return (
    <Box display="flex" justifyContent="center">
      <Box width="500px">
        <Alert severity="warning" variant="filled">
          <AlertTitle>You seem to be lost!</AlertTitle>
          Hey there scientist! I wasn&apos;t able to find the page you were looking for ... maybe
          you can check the name and try again?
        </Alert>
      </Box>
    </Box>
  );
}
