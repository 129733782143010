/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import { Check, Clear } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import Sandwich from '../components/SuperheroSandwich';
import { useAuth, useAuthActions } from '../contexts/Authentication';

export default function Login() {
  const { forgot, emailSent } = useParams();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { isLoading } = useAuth();
  const { confirmSignup, resetPassword } = useAuthActions();
  const validatePassword = () =>
    password.length >= 6 && password.match(/[0-9]+/) && password.match(/[A-Z]+/);
  const validateForm = () =>
    (forgot ? emailSent.length > 0 : true) && validatePassword() && confirmPassword === password;

  const renderAppropriate = () => {
    if ((forgot && emailSent) || (!forgot && !emailSent)) {
      return [
        <Grid item xs>
          <Typography align="center">
            Your account was setup/reset. Please type the new password you would like.
          </Typography>
        </Grid>,
        <Grid container xs justify="center" spacing={2} alignItems="center" direction="column">
          <Typography>Your password must contain:</Typography>
          <Box marginY="5px" />
          <Chip
            color={password.length >= 6 ? 'primary' : 'secondary'}
            size="small"
            icon={password.length >= 6 ? <Check /> : <Clear />}
            label="At least 6 characters"
          />
          <Box marginY="5px" />
          <Chip
            color={password.match(/[0-9]+/) ? 'primary' : 'secondary'}
            size="small"
            icon={password.match(/[0-9]+/) ? <Check /> : <Clear />}
            label="At least 1 number"
          />
          <Box marginY="5px" />
          <Chip
            color={password.match(/[A-Z]+/) ? 'primary' : 'secondary'}
            size="small"
            icon={password.match(/[A-Z]+/) ? <Check /> : <Clear />}
            label="At least 1 uppercase letter"
          />
        </Grid>,
        <Grid item xs>
          <TextField
            id="outlined-code-input"
            label="Code"
            type="number"
            autoComplete="current-code"
            variant="outlined"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
        </Grid>,
        <Grid item xs>
          <TextField
            id="outlined-password-input"
            label="New Password"
            error={!validatePassword()}
            type="password"
            autoComplete="new-password"
            variant="outlined"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </Grid>,
        <Grid item xs>
          <TextField
            id="outlined-new-password-input"
            label="Retype New Password"
            type="password"
            autoComplete="retype-new-password"
            variant="outlined"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </Grid>,
        <Grid item xs>
          <Button
            variant="contained"
            color="primary"
            disabled={!validateForm() || isLoading}
            onClick={(event) => {
              event.preventDefault();
              if (forgot && emailSent) {
                confirmSignup(emailSent, code, password);
              } else {
                resetPassword('', password);
              }
            }}
          >
            Confirm
          </Button>
        </Grid>,
      ];
    }
    if (forgot && !emailSent) {
      return [
        <Grid item xs>
          <Typography align="justify">
            Please type the email you want to reset the password for. We will send an email when you
            click the button.
          </Typography>
        </Grid>,
        <Grid item xs>
          <TextField
            fullWidth
            id="outlined-email-input"
            label="Email"
            type="email"
            autoComplete="current-email"
            variant="outlined"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            disabled={email.length === 0 || !email.includes('@') || isLoading}
            onClick={(event) => {
              event.preventDefault();
              resetPassword(email);
            }}
          >
            Request Code
          </Button>
        </Grid>,
      ];
    }
    return null;
  };

  return (
    <Container>
      <Typography variant="h5" align="center" gutterBottom>
        This is where you reset your password.
        <br />
        Keep the new password safe!
      </Typography>
      <Sandwich>
        <Grid
          container
          item
          xs
          zeroMinWidth
          spacing={3}
          direction="column"
          justify="center"
          alignItems="center"
        >
          {renderAppropriate()}
        </Grid>
      </Sandwich>
    </Container>
  );
}
