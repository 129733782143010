import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { useAlert, useAlertActions } from '../contexts/Alert';

export default function Alert() {
  const { showAlert, alertInfo, alertType } = useAlert();
  const { hideAlert } = useAlertActions();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    hideAlert();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showAlert}
        autoHideDuration={7000}
        onClose={handleClose}
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <MuiAlert
          onClose={handleClose}
          severity={alertType || 'info'}
          elevation={6}
          variant="filled"
        >
          {alertInfo}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
