import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth, useAuthActions } from '../../contexts/Authentication';

// HOC wrapper to secure pages
export default function withNoAuth(Page) {
  return () => {
    const { isAuthenticated, isAuthenticating } = useAuth();
    const { refreshSession } = useAuthActions();
    const history = useHistory();

    useEffect(() => {
      refreshSession();
    }, [refreshSession]);
    if (!isAuthenticated && !isAuthenticating) {
      return <Page />;
    }
    if (isAuthenticated) {
      history.push('/dashboard');
    }
    return null;
  };
}
