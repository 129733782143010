import React from 'react';
import { Redirect } from 'react-router-dom';
import StudentInduction from './Induction';
import ParentDashboard from './ParentDashboard';
import { useAuth } from '../contexts/Authentication';
import { useUser } from '../contexts/User';

export default function Dashboard() {
  const { cognitoUserObject } = useAuth();
  const { currentUser } = useUser();
  let isConfirmed;
  let studentId;
  let isParent;
  if (cognitoUserObject) {
    isParent = cognitoUserObject.attributes['custom:isParent'] === 'true';
  }
  if (isParent) {
    return <ParentDashboard />;
  }
  if (currentUser && !isParent) {
    isConfirmed = currentUser.confirmed;
    studentId = currentUser.studentId;
  }
  if (isConfirmed === 'true' && studentId) {
    return <Redirect to="/project" />;
  }
  if (isConfirmed === 'false' || isConfirmed === 'rejected' || currentUser === 'Not Found') {
    return <StudentInduction />;
  }

  return false;
}
